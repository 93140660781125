import React from "react";
import style from "./style/editModal.module.css";
const EditNameModalFooter = ({ handleModalClose }) => {
  return (
    <div className={style.editNameModalFooter}>
      <button className={style.editNameSaveButton}> Save </button>
      <button className={style.editNameCancleButton} onClick={handleModalClose}>
        
        Cancle
      </button>
    </div>
  );
};

export default EditNameModalFooter;
