import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { editModalStyle } from "./style/editModalStyle";
import style from "./style/editModal.module.css"
import EditModalHeader from "./EditModalHeader";
import EditModalMiddle from "./EditModalMiddle";
import EditNameModalFooter from "./EditNameModalFooter";
const EditName = ({ showModal, handleModalClose }) => {
  return (
 
      <Modal
        open={showModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...editModalStyle }}>
         <div className={style.editName}>
                <EditModalHeader />
                <EditModalMiddle />
                <EditNameModalFooter handleModalClose={handleModalClose}/>
         </div> 
        </Box>  
      </Modal>
   
  );
};

export default EditName;
