import React from "react";
import style from "../../style/divisionManage.module.css";
import download from "../../../../../images/division/download.svg";
const HeaderManage = () => {
  return (
    <div className={style.headerManage}>
      <div className={style.manageTeamName}>
        <span> Team Name </span>
      </div>
      <div className={style.manageCV}>
        <button className={style.buttonCV}>
      
          <img src={download} alt="download" />
          <span> Upload Tean via CSV</span>
        </button>

        <button className={style.manageEdit}> Edit </button>
        <button className={style.manageView}> View Game list </button>
      </div>
    </div>
  );
};

export default HeaderManage;
