import React from 'react'
import AboutSchedulesFooter from './aboutSchedulesPages/schedulesContainer/AboutSchedulesFooter'


import AboutSchedulesName from "./aboutSchedulesPages/schedulesContainer/AboutSchedulesName"
 import ScheduleDivision from './aboutSchedulesPages/schedulesContainer/ScheduleDivision'
 import SchedulesCheckbox from './aboutSchedulesPages/schedulesContainer/SchedulesCheckbox'
 import ScheduleSelectDivision from './aboutSchedulesPages/schedulesContainer/ScheduleSelectDivision'
 import ScheduleSettings from './aboutSchedulesPages/schedulesContainer/ScheduleSettings'
 import SheduleChoise from './aboutSchedulesPages/schedulesContainer/SheduleChoise'
import style from "./style/aboutSchedules.module.css"
const AboutSchedules = () => {
  return (
  
<div className={style.aboutSchedulesHeader}>
  <AboutSchedulesName />
  <ScheduleSettings />
  <ScheduleDivision />
  <SheduleChoise />
  <ScheduleSelectDivision />
  <SchedulesCheckbox />
  <AboutSchedulesFooter />


</div>
    
    
  )
}

export default AboutSchedules