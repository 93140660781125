import React from "react";
import style from "../style/edit.module.css";
const EditPagesMiddle = ({ venue, onChange }) => {
  return (
    <div className={style.editPagesMiddle}>
      <div className={style.priority}>
        <span className={style.span}> Venue Priroty</span>
        <input
          type="number"
          data-id="priority"
          value={venue.priority}
          onChange={onChange}
          className={style.input}
        />
      </div>

      <div>
        <div className={style.venuesNotes}>
          <span className={style.span}> Venues notes </span>
          <textarea
            className={style.textarea}
            data-id="notes"
            value={venue.notes}
            onChange={onChange}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default EditPagesMiddle;
