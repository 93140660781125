import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


import style from "../style/registrationStyle.module.css"
import {CreateUser} from "../../../features/slice/loginSlice/loginApi/loginApi";
import {createUser} from "../../../features/slice/loginSlice/loginSlice";

const RegistrationForm = () => {
    const {user} = useSelector(state=>state.hockey)

    const dispatch = useDispatch()

    const handleChange = (e) => {
        dispatch(createUser({[e.target.name]:e.target.value}))
    }
    const handleClick = ()=>{

      dispatch(CreateUser({user}))
    }
  return (
    <div className={style.regisrationForm}>

           <div className={style.welcome}>
           <h1> Welcome to Company Name </h1>
            </div> 

            <div className={style.registrationInput}>

                    <input type="text" name='first_name' placeholder='First Name' value={user.first_name} className={style.regInputs}  onChange={handleChange}/>
                    <input type="text" name='last_name' placeholder='Last Name' value={user.last_name} className={style.regInputs} onChange={handleChange}/>
                    <input type="text" name='email' placeholder='Email'     value={user.email}className={style.regInputs} onChange={handleChange}/>
                    <input type="password" name='password' placeholder='Password' value={user.password} className={style.regInputs} onChange={handleChange}/>
                    <input type="text" name='organization_name' placeholder='Organization Name' value={user.organization_name}  className={style.organization} onChange={handleChange}/>
                    
            </div> 
            <div className={style.signUp}>
                <button onClick={handleClick}> Sign up</button>
                <span>Already have an account?  <Link to="/login"> <span>Login     </span>   </Link>       </span>
            </div>

    </div>
  )
}

export default RegistrationForm