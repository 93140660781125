import { createAsyncThunk } from "@reduxjs/toolkit";
import { $authHost } from "../../../axios";

export const CreateVenue = createAsyncThunk(
    "create/venues",
   async(obj)=>{
       const response = await $authHost.post("/create-venue",obj)
       return response.data
    }
)

export const getVenuDate = createAsyncThunk(
    "get/venue",
    async()=>{
            const response = await $authHost.get("/get-venues")
            return response.data
    }
)

export const EditVenue = createAsyncThunk(
    "edit/venue",
    async(data)=>{
        
       
        const response = await $authHost.post("/edit-venue",data)
        
        return response.data
    }
)


export const GetVenueUserId = createAsyncThunk(
    "user/id",
    async(id)=>{
        const response = await $authHost.get(`/get-venue/${id}`)
        return response.data[0] 
    }
)