import React from 'react'
import PlusPage from '../../plusPage/PlusPage'
import style from "../style/seasonTeam.module.css"
const SeasonTeamTitle = () => {
  return (
    <div className={style.seasonTeam}>

        <PlusPage />

        <div className={style.textTitle}>
            <h1>Create League and Season </h1>
            <span>Enter Team names</span>
            <p> You can always edit these later</p>
        </div>


        <div className={style.divisonNameTable}>

            <div className={style.divisonTableTitle}>
                <div className={style.divisionColor}>
                    <div></div>
                    <span>Divison name</span>
                </div>

                <div className={style.abbrevitation}>
                    <span> Abbrevitation</span>
                    <div> <span> - </span> </div>

                </div>
                
            </div>

            <div className={style.divisionInput}>
                <input type="text" placeholder='Division1' className={style.teamDivision}/>
                <input type="text" placeholder='D1'  className={style.numberDivison}/>

            </div>


        </div>


        
    </div>
  )
}

export default SeasonTeamTitle