import { createAsyncThunk } from "@reduxjs/toolkit";
import { $authHost } from "../../../axios";

export const DivisionAdd = createAsyncThunk("division", async ({ data }) => {
  await $authHost.post("/create-division", data);
});

export const CreateTeam = createAsyncThunk(
  "createTeam",
  async ({ createTeam }) => {
    await $authHost.post("/create-team", createTeam);
  }
);

export const getDivisionTeam = createAsyncThunk("getDivison", async () => {
  const response = await $authHost.get("/get-divisions");

  return response.data;
});

export const CreateCoach = createAsyncThunk("creat/coach", async (data) => {
  if (data.csv) {
    const { csv, person_role } = data;

    const formData = new FormData();

    formData.append("csv", csv);
    formData.append("person_role", person_role);
    const response = await $authHost.post("/create-person", formData);

    return response.data;
  } else {
    const coach = data;

    const response = await $authHost.post("/create-person", coach);
  

    return response.data;
  }
});

export const GetAllCoach = createAsyncThunk("get/all/coach", async () => {
  const response = await $authHost.get("/get-coaches");

  return response.data;
});
