export const chooseColorData = [
  {
    color:"#C6262E",
  },

  {
    color:"#F37329",    
  },

  {
    color:"#F9C440",    
  
  },

  {
    color:"#68B723",    
  
  },

  {
    color:"#28BCA3",    
  },

  {
    color:"#3689E6",    
  
  },

  {
    color:"#A56DE2",    
  
  },
  
  {
    color:"#DE3E80",    
  
  },
  {
    color:"#715344",    
  
  },

  {
    color:"#ABACAE",    
  
  },

  {
    color:"#485A6C",    
  },

  {
    color:"#333333",    
  
  },




];
