export const createVenuesModalStyle = {
    position: "absolute",
    background: " #FFFFFF",
    width: "1160px",
    left: "438px",
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    
    top: "20px",
    height:"900px",
    borderRadius: "40px 0px 0px 40px",
    boxShadow:
      " 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
}