import React from "react";
import calendar from "../../../images/gamelist/calendar.svg";
import search from "../../../images/gamelist/search.svg";
import style from "../style/gameListStyle.module.css";
const GameListSearch = () => {
  return (
    <div className={style.gameListSearch}>
      <div className={style.viewCalendar}>
        <img src={calendar} alt="calendar" />
        <span> View Calendar </span>
      </div>
      <div className={style.gameSerach}>
        <span className={style.searchIcon}>
          <img src={search} alt="seach" />
        </span>
        <input type="serach" placeholder="search" />
      </div>
      <div className={style.gameCalendarButtons}>
                <button className={style.gameEditButton}> Edit </button>
                <button className={style.gameUnscheduleButton}> Unschedule </button>
                <button className={style.gameDeleteButton}> Delete </button>
      </div>
    </div>
  );
};

export default GameListSearch;
