import React from 'react'
import SettingsHeader from './SettingsHeader'
import { Outlet } from 'react-router-dom'
import style from "./style/settingsStyle.module.css"
const Settings = () => {
  return (
    <div className={style.settings} >

      <SettingsHeader />
           <Outlet />
    </div>
  )
}

export default Settings