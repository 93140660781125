import React from 'react'
import style from "../../style/aboutSchedules.module.css"
import top from "../../../../../images/schedules/vectortop.svg"
import bottom from "../../../../../images/schedules/vectorbottom.svg"
const AboutSchedulesFooterHeader = () => {
  return (
    <div className={style.aboutSchedulesFooterHeader}>
        <div className={style.title}>
            <span> Date </span>
        </div>

        <div className={style.titleWitchIcon}>
            <span> Date </span>
            
            <img src={bottom} alt="bottom" className={style.imageBottom} />
                <img src={top} alt="top" className={style.imageTop}/>
              
            
        </div>

        <div className={style.titleWitchIcon}>
            <span> Start </span>
            
            <img src={bottom} alt="bottom" className={style.imageBottom} />
                <img src={top} alt="top" className={style.imageTop}/>
              
            
        </div>

        <div className={style.title}>
            <span> Finish </span>
        </div>
        <div className={style.title}>
            <span> Home </span>
        </div>

        <div className={style.title}>
            <span> Visitor </span>
        </div>

        <div className={style.title}>
            <span> Venue </span>
        </div>

        <div className={style.title}>
            <span> Surface </span>
        </div>

        <div className={style.title}>
            <span> Last edited </span>
        </div>

        <div className={style.title}>
            <span> Visitor Division </span>
        </div>
    </div>
  )
}

export default AboutSchedulesFooterHeader