import React from 'react'

const CompanySelect = () => {
  return (
    <div className='company-select'>
        <select className='select-style'> <option> Select League</option> </select>
        <select className='select-style'> <option> Select Name </option> </select>
    </div>
  )
}

export default CompanySelect