import { createSlice } from "@reduxjs/toolkit";


const calendarSlice = createSlice({
    name:"calendar",
     initialState:{

     },
     reducers:{}
})

export const calendarSliceReduce = calendarSlice.reducer


