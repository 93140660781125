import React from "react";
import style from "../style/selectDivision.module.css";
import styles from "../../createSeason/style/create.module.css";

const SelectDivisionForm = ({ nextPage, cancelPage }) => {
  return (
    <div className={style.selectDivisonForm}>
      <div>
        <span> Division 1</span>
        <input type="number" />
      </div>

      <div>
        <span> Division 2 </span>
        <input type="number" />
      </div>

      <div>
        <span> Division 3 </span>
        <input type="number" />
      </div>

      <div className={style.divisonFormButton}>
        <button className={styles.continue} onClick={nextPage}>
         
          Continue
        </button>
        <button className={styles.cancel} onClick={cancelPage}>
          
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SelectDivisionForm;
