import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createVenuesModalStyle } from './style/createVenuesModalStyle';
import CreateVenuesHeader from './createVenuesPages/CreateVenuesHeader';
import CreateVenuesForms from './createVenuesPages/CreateVenuesForms';
import CreateVenuesMiddle from './createVenuesPages/CreateVenuesMiddle';
import CreateVenuesFooter from './createVenuesPages/CreateVenuesFooter';
import { useDispatch } from 'react-redux';
import { CreateVenue } from '../../../../component/features/slice/venuesSlice/venuesApi';
import { useNavigate } from 'react-router-dom';

const CreateVenues = () => {
    const [venues,setVenues] = useState({
        name:"",
        first_address:"",
        second_address:"",
        city:"",
        state:"",
        zip_code:"",
        country:"",
        latitude:"",
        longitude:"",
        google_map_link:"",
        primary_phone:"",
        secondary_phone:"",
        primary_email:"",
        secondary_email:"",
        priority:"",
        notes:"",     
        playing_surface:"",
    })
    const navigate = useNavigate()
    
    const [isChecked, setIsChecked] = useState(false)
const dispatch = useDispatch()
    const handleChange = (e) => {
        const{value,dataset} = e.target
   
        setVenues({...venues,[dataset.id]:value})
    }

    const handleCreateVenue = () => {
      const  as_home_team = isChecked ? 1 : 0
       const obj = {...venues,as_home_team}
       dispatch(CreateVenue(obj )).unwrap().then(res=>{
        if(res){
          navigate("/venues")
        }
       })
    }

    const handleClose = ()=>{
      navigate("/venues")
    }

  return (
    <Modal
    open={true}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{...createVenuesModalStyle }}>
        <CreateVenuesHeader handleCreateVenue={handleCreateVenue} handleClose={handleClose}/>   
        <CreateVenuesForms  venues={venues}  handleChange={handleChange}/>    
        <CreateVenuesMiddle venues={venues}  handleChange={handleChange}/>
        <CreateVenuesFooter  isChecked={isChecked} handleChange={handleChange} setIsChecked={setIsChecked} venue={venues}/>
    
    </Box>
  
  </Modal>
  )
}

export default CreateVenues


