import React from 'react'
import style from "../style/gameListStyle.module.css"
const GameListMiddle = () => {
  return (
    <div className={style.gameListMiddle}>
        <div className={style.gameChoose}>
        <span> Divisions</span>
        <select>
          <option>Division 1</option>
        </select>
      </div>

      <div className={style.gameChoose}>
        <span> Teams</span>
        <select>
          <option>Team name</option>
        </select>
      </div>

      <div className={style.gameChoose}>
        <span> Venues</span>
        <select>
          <option>Venue name</option>
        </select>
      </div>

    </div>
  )
}

export default GameListMiddle