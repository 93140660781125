import edit from "../../../images/division/edit.svg"
import img from "../../../images/division/image.jpeg"
export const divisionData=[
    {
        name:"Division Name",
        icon:edit,
        teams:"Teams",
        teamsName:"Team name",
        image:img,
        length:"+5",
        style:{
            background: "#F2F4F7",
            borderRadius: "16px",
            color:"#344054",
            width: "70px",
            height: "22px"
          },
        schedules:"Schedules",
        create:"Create",
        scheduleName:"Schedule name"
    },

    {
        name:"Division Name",
        icon:edit,
        teams:"Teams",
        teamsName:"Team name",
        image:img,
        length:"+5",
        style:{
            background: "#F2F4F7",
            borderRadius: "16px",
            color:"#344054",
            width: "70px",
            height: "22px"
          },
        schedules:"Schedules",
        create:"Create",
        scheduleName:"Schedule name"
    },

    {
        name:"Division Name",
        icon:edit,
        teams:"Teams",
        teamsName:"Team name",
        image:img,
        length:"+5",
        style:{
            background: "#F2F4F7",
            borderRadius: "16px",
            color:"#344054",
            width: "70px",
            height: "22px"
          },
        schedules:"Schedules",
        create:"Create",
        scheduleName:"Schedule name"
    },

    {
        name:"Division Name",
        icon:edit,
        teams:"Teams",
        teamsName:"Team name",
        image:img,
        length:"+5",
        style:{
            background: "#F2F4F7",
            borderRadius: "16px",
            color:"#344054",
            width: "70px",
            height: "22px"
          },
        schedules:"Schedules",
        create:"Create",
        scheduleName:"Schedule name"
    },

    {
        name:"Division Name",
        icon:edit,
        teams:"Teams",
        teamsName:"Team name",
        image:img,
        length:"+5",
        style:{
            background: "#F2F4F7",
            borderRadius: "16px",
            color:"#344054",
            width: "70px",
            height: "22px"
          },
        schedules:"Schedules",
        create:"Create",
        scheduleName:"Schedule name"
    },

    {
        name:"Division Name",
        icon:edit,
        teams:"Teams",
        teamsName:"Team name",
        image:img,
        length:"+5",
        style:{
            background: "#F2F4F7",
            borderRadius: "16px",
            color:"#344054",
            width: "70px",
            height: "22px"
          },
        schedules:"Schedules",
        create:"Create",
        scheduleName:"Schedule name"
    },



    





]

