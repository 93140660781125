import React, { useState } from 'react'
import style from "./style/adminModalStyle.module.css";

import { useNavigate } from 'react-router-dom';
import AdminModalFooter from './AdminModalFooter';
import {checkboxData} from "./adminModalData/checkboxData.js"



const AdminModalMiddle = ({handleClose}) => {
  const navigate = useNavigate()
  const [link, setLink] = useState('')
  const handleSubmit = () => {
    navigate(link)
  }
  return (
    <div className={style.adminModalMiddle}>
        {
          checkboxData.map((item, index) => {
            return (
              <div className={style.chooisDivision} key={index}>
              <input type="radio" name="adminModalRadio" onChange={() => setLink(item.link)} />
              <img src={item.img} alt="schedule"/>
              <span> { item.name } </span>
            </div>
            )
          })
        }

      <AdminModalFooter handleClose={handleClose} handleSubmit={handleSubmit} />
    </div>
  )
}

export default AdminModalMiddle