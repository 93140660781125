export const date = [
    {
        name:"Date"
    },
    
    {
        name:"Day"
    },

    {
        name:"Start"
    },
    {
        name:"Finish"
    },
    {
        name:"Home"
    },

    {
        name:"Visitor"
    },

    {
        name:"Venue"
    },

    {
        name:"Surface"
    },

    {
        name:"Last edited"
    },

    {
        name:"Visitor Division"
    },

    {
        name:"Home Division"
    },

   
]