import React, { useState } from 'react'
import { chooseColorData } from '../divisionData/chooseColorData'
import style from "./style/divisionModalStyle.module.css"
const ChooseColor = ({ chooseHandleColor }) => {
  const [color, setColor] = useState('')
  const handleChange = (e) => {
    chooseHandleColor(e)
    setColor(e)
  }
  return (
    <div className={style.chooseColor}>
        <span> Color </span>
        <div className={style.colors}>
        {
            chooseColorData.map((item,index)=>(
                <div key={index}>
                  <div onClick={() => 
                    handleChange(item.color)} className={`${style.color} ${color === item.color && style.activeColor}`} style={{backgroundColor: item.color}}>  </div>
                </div>
            ))
        }
        </div>
       

    </div>
  )
}

export default ChooseColor