export const leagueTable = [
    {
        name:"League name",
        seasoneNumber:"Seasons number",
        seasonePeriod:"Seasons period"

    },
    {
        name:"League name",
        seasoneNumber:"Seasons number",
        seasonePeriod:"Seasons period"

    },
    {
        name:"League name",
        seasoneNumber:"Seasons number",
        seasonePeriod:"Seasons period"

    },
]