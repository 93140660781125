import React from 'react'
import style from "./style/divisionModalStyle.module.css"
const DivisionModalEditMiddle = ({data, onChange}) => {
  return (
    <div className={style.divisionModalEditMiddle}>
        <div className={style.divisionNumber}>  
            <span> Division name </span>
            <input type="text" data-id="name" placeholder='Division 14' value={data.name} onChange={onChange}/>
        </div>
        <div className={style.costomCode}>
            <div className={style.divisionAbbreviation}>
                <span>Abbreviation</span>
                <input type="text" data-id="abbreviation" placeholder='8' value={data.abbreviation} onChange={onChange}/>
            </div>

            <div className={style.divisionAbbreviation}>
                <span>Custom Code</span>
                <input type="text" placeholder='8' data-id="customCode" value={data.customCode} onChange={onChange}/>
            </div>
        </div>
    </div>
  )
}

export default DivisionModalEditMiddle