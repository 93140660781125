import React from 'react'

import style from "./style/addStyle.module.css"
const AddMiddle = () => {
  return (
    <div className={style.addMiddle}>
            <div className={style.selectDays}>
            Select Day(s) of week
            </div>

            <div className={style.days}>
                <div>
                <input type="checkbox" />
                <span>Sun</span>
                </div>

                <div>
                <input type="checkbox" />
                <span>Mon </span>
                </div>

                <div>
                <input type="checkbox" />
                <span> Tue </span>
                </div>

                <div>
                <input type="checkbox" />
                <span> Wed </span>
                </div>

                <div>
                <input type="checkbox" />
                <span> Thu </span>
                </div>

                <div>
                <input type="checkbox" />
                <span> Fri </span>
                </div>

                <div>
                <input type="checkbox" />
                <span> Sat </span>
                </div>
              
            </div>

            <div className={style.constraint}> Choose Constraint Type</div>

    </div>
  )
}

export default AddMiddle