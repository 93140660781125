import React from 'react'
import style from "./schedulesstyle/schedules.module.css"
import Container from '../../../component/container/Container'
import SchedulesHeader from './schedulespages/SchedulesHeader'
import SchedulesName from './schedulespages/SchedulesName'
const Schedules = () => {
  return (
    <Container>
         <div className={style.schedulesMain}>
          <SchedulesHeader />
          <SchedulesName />
         </div>
    </Container>
   
  )
}

export default Schedules