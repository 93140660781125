import React from 'react'
import style from "../../style/divisionManage.module.css";
const MiddleManage = () => {
  return (
    <div className={style.middleManage}>
      <span> Team in Division1</span>
      <button> View </button>

    </div>
  )
}

export default MiddleManage