import React from "react";
import style from "../style/details.module.css";
import message from "../../../../images/settings/message.svg"
const DetailsForm = () => {
  return (
    <div className={style.detailsForm}>
      <div className={style.name}>
        <span> Name </span>
        <div className={style.nameForm}>
          <input type="text" placeholder="Jane" />
          <input type="text" placeholder="Cooper" />
        </div>
      </div>

      <div className={style.email}>
        <span> Email address </span>

        <div className={style.message}>
          <input type="text" placeholder="dolores.chambers@example.com" />
          <img src = {message} alt="message" />
        </div>
      </div>


      <div className={style.zone}>
        <span> Timezone </span>

        <div className={style.timezone}>
          <select   className={style.pacific}>
            <option>
           Pacific Standard Time (PST) UTC−08:00 
            </option>
          </select>
         
         
        </div>
      </div>

      
    </div>
  );
};

export default DetailsForm;
