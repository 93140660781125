import React from 'react'
import style from "./style/addCoach.style.module.css"
import greenplus from "../../../../../images//division/greenplus.svg"
const AddCoachModalHeader = () => {
  return (
    <div className={style.addCoach}>
        <div className={style.green}>
            <div className={style.chooseCoach}>
            <img src={greenplus} alt="greenplus" />
            </div>
            
            <span className={style.chooseText}>Choose coach for the team or Create new Coach</span>
        </div>

    </div>
  )
}

export default AddCoachModalHeader