import React from "react";

import GameModalPages from "../gameModalPages/index.js"
import style from "../style/gameListStyle.module.css";
const GamePageBallances = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  
  return (
    <div className={style.gamePageBallances}>
 <button className={style.showButtonBallance} onClick={handleOpen}>Show Balances </button>
      <button className={style.printButton} handleOpen={handleOpen}>Print all Balances </button>
        <GameModalPages open={open}/>
    </div>
  );
};

export default GamePageBallances;
