import React from "react";
import plus from "../../images/admin/plus.png";
import AdminModal from "../adminModal";

const AdminButtonCreate = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="admin-button">
      <button className="admin-button-size" onClick={handleOpen}>
     
        <img src={plus} alt="plus" /> Create
      </button>
      <AdminModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default AdminButtonCreate;
