import React from 'react'

import CompanyBottom from './companyPages/CompanyBottom'
import CompanyMenuNavBar from './companyPages/CompanyMenuNavBar'
import CompanyName from './companyPages/CompanyName'
import CompanySelect from './companyPages/CompanySelect'
import CompanySettings from './companyPages/CompanySettings'
import CompanyUser from './companyPages/CompanyUser'
import "./style/companyMenu.css"
const CompanyMenu = () => {
  return (
    <div className='company-menu'>
      <div className='company-menu-fixed'>
      <CompanyName />
      <CompanySelect />
      <CompanyMenuNavBar />
      <CompanySettings />
      <CompanyBottom />
      <CompanyUser />
      </div>
    </div>
  )
}

export default CompanyMenu