import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { settingsPage } from './settingsData/settingsPage';
import style from "./style/settingsStyle.module.css"
const SettingsHeader = () => {
  const { pathname } = useLocation();

  return (
    <div className={style.settingsHeader}>
        <span> Settings</span>
        <div className={style.settingsPage}>
           {settingsPage.map((item,index)=>(
            <Link key={index} to={item.href}><p className={item.href === pathname ? style.settingsPageNavigation : null}>{item.name}</p></Link>
           ))}
        </div>
    </div>
  )
}

export default SettingsHeader