import React from "react";
import style from "./style/creatCoach.module.css";
import Button from "@mui/material/Button";
import download from "../../../../../images/division/download.svg";
const CoachModalUpload = ({onChange}) => {
  return (
    <div className={style.coachModalUpload}>
    <Button variant="contained" component="label" className={style.uploadButton} >
      <img src={download} alt="download" />  <span className={style.uploadSpan}>  Upload Divisions via CSV</span>
        <input hidden accept="image/*"  type="file"    onChange={onChange} />
      </Button>
    </div>
  );
};

export default CoachModalUpload;


