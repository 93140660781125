import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {createButtonStyle} from "./style/createButtonStyle"
import AdminModalHeader from './AdminModalHeader';
import AdminModalMiddle from './AdminModalMiddle';

const AdminModal = ({open,handleClose}) => {
  return (
    <Modal
    open={open}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...createButtonStyle }}>
      <AdminModalHeader />
      <AdminModalMiddle handleClose={handleClose}/>
    </Box>
  </Modal>
  )
}

export default AdminModal