import React from 'react'
import style from "../../style/aboutSchedules.module.css"
const AboutSchedulesName = () => {
  return (
    <div className={style.scheduleTextName}>
        Visitor Division
    </div>
  )
}

export default AboutSchedulesName