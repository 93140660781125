import React from 'react'
import RegistrationForm from './registrationPages/RegistrationForm'
import RegistrationImage from './registrationPages/RegistrationImage'
import style from "./style/registrationStyle.module.css"
const Registration = () => {
  return (
    <div className={style.registration}>
        <RegistrationImage />
        <RegistrationForm />

    </div>
  )
}

export default Registration