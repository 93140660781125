import React from 'react'
import style from "../style/create.module.css"


import PlusPage from '../../plusPage/PlusPage'
const CreateSeasonTitle = ({nextPage,cancelPage}) => {
  return (
    <div className={style.createSeason}>
      <PlusPage />
        <h1>  Crate League and Season </h1>
        <span> How many divisins? </span>
        <p> Please note maximal divisions number is 30 </p>

        <input type="number" />

        <div className={style.buttonsCreate}>
            <button className={style.continue} onClick={nextPage}> Continue </button>
            <button className={style.cancel} onClick={cancelPage}> Cancel</button>

        </div>



    </div>
  )
}

export default CreateSeasonTitle