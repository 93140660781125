import React from 'react'
import RegistrationImage from '../registration/registrationPages/RegistrationImage'
import LoginForm from './loginPage/LoginForm'
import style from  "./style/loginStyle.module.css"
const Login = () => {
  return (
    <div className={style.login}>
      <RegistrationImage />
      <LoginForm />
    </div>
  )
}

export default Login