import React from 'react'
import style from "./style/deleteModal.module.css"
import deleteIcon from "../../../../../../images/schedules/delete.svg"
const DeleteModalHeader = () => {
  return (
    <div className={style.deleteModalHeader}>
        
        <div className={style.deleteIconSize}> <img src={deleteIcon} alt="delet" /></div>
        <div className={style.deleteText}> Do you want to delete a schedule?</div>
    </div>
  )
}

export default DeleteModalHeader