import React from "react";
import style from "../style/edit.module.css";
const EditPagesForm = ({ venue, handleChange }) => {
  return (
    <div className={style.editPagesForm}>
      <div className={style.name}>
        <div>
          <span className={style.span}> Name </span>
          <input
            type="text"
            name="name"
            value={venue.name}
            className={style.input}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={style.addressForm}>
        <div className={style.addressInput}>
          <span className={style.span}> Address line</span>
          <input
            type="text"
            name="first_address"
            value={venue.first_address}
            className={style.input}
            onChange={handleChange}
          />
        </div>
        <div className={style.addressInput}>
          <span className={style.span}> Address line</span>
          <input
            type="text"
            name="second_address"
            value={venue.second_address}
            className={style.second_address}
            onChange={handleChange}
          />
        </div>

        <div className={style.addressInput}>
          <span className={style.span}> City</span>
          <input
            type="text"
            name="city"
            value={venue.city}
            className={style.city}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={style.addressForm}>
        <div className={style.addressInput}>
          <span className={style.span}> State </span>
          <input
            type="text"
            name="state"
            value={venue.state}
            className={style.state}
            onChange={handleChange}
          />
        </div>
        <div className={style.addressInput}>
          <span className={style.span}> zip_code</span>
          <input
            type="text"
            name="zip_code"
            value={venue.zip_code}
            className={style.second_address}
            onChange={handleChange}
          />
        </div>

        <div className={style.addressInput}>
          <span className={style.span}> Country</span>
          <input
            type="text"
            name="country"
            value={venue.country}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={style.addressForm}>
        <div className={style.addressInput}>
          <span className={style.span}> Latitude </span>
          <input
            type="text"
            name="latitude"
            value={venue.latitude}
            onChange={handleChange}
          />
        </div>
        <div className={style.addressInput}>
          <span className={style.span}> Longitude</span>
          <input
            type="text"
            name="longitude"
            value={venue.longitude}
            onChange={handleChange}
          />
        </div>

        <div className={style.addressInput}>
          <span className={style.span}> Google map link</span>
          <input
            type="text"
            name="google_map_link"
            value={venue.google_map_link}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={style.addressForm}>
        <div className={style.addressInput}>
          <span className={style.span}> Primary phone </span>
          <input
            type="text"
            name="primary_phone"
            value={venue.primary_phone}
            onChange={handleChange}
          />
        </div>
        <div className={style.addressInput}>
          <span className={style.span}> Secondary Phone number</span>
          <input
            type="text"
            name="secondary_phone"
            value={venue.secondary_phone}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={style.addressForm}>
        <div className={style.addressInput}>
          <span className={style.span}> Primary email </span>
          <input
            type="text"
            name="primary_email"
            value={venue.primary_email}
            className={style.primary_email}
            onChange={handleChange}
          />
        </div>
        <div className={style.addressInput}>
          <span className={style.span}> Secondary email</span>
          <input
            type="text"
            name="secondary_email"
            value={venue.secondary_email}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPagesForm;
