import React from 'react'
import style from "./style/creatCoach.module.css"
import greenplus from "../../../../../images/division/greenplus.svg"
const CoachModal = ({coach,onChange}) => {
  return (
    <div className={style.coachModal}>
        <div className={style.titleCoach}>
            <div className={style.iconGreenPlus}> <img src={greenplus} alt="greenplus"/> </div>
            <span>Create Coach </span>
        </div>

        <div className={style.fullName}>
            <div className={style.firstName}>
                <label> First name </label>
                <input type="text" name="first_name" value={coach.first_name}  placeholder='First name' onChange={onChange}/>
            </div>

            <div className={style.firstName}>
                <label> Last name </label>
                <input type="text" name='last_name' value={coach.last_name} placeholder='Last name' onChange={onChange}/>
            </div>
        </div>

        <div className={style.personRoles}> <span> Person Roles</span></div>

    </div>
  )
}

export default CoachModal