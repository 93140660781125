import React from 'react'
import style from "./style/deleteModal.module.css"
const DeleteButtons = ({handleModalClose}) => {
  return (
    <div className={style.deleteButtons}>
      <button className={style.deleteButtonSize}> Delete </button>
      <button className={style.cancleButtonSize} onClick={handleModalClose}>
        
      Cancel
      </button>

    </div>
  )
}

export default DeleteButtons