import plus from "../../../images/division/plus.svg"
import edit from "../../../images/division/edit.svg"
import img from "../../../images/division/image.jpeg"
import AddCoachModal from "../divisionManage/managePage/modal"
import EditModal from "../divisionManage/managePage/editModal"
import Add from "../divisionManage/managePage/add"
export const manageData = [
    {
        name:"Coaches",
        icon:plus,
        buttonName:"Add Coach",
        length:"+5",
        Modal:AddCoachModal,
        style:{
            width:"139px",
            height:"44px"
        },
        href:"/addcoach",

        image:img
    },
    {
        name:"Players",
        icon:plus,
        buttonName:"Add Staff",
        teamName:"Team Name",
        length:"+5",
        style:{
            width:"129px",
            height:"44px"
        },
        image:img
    },

    {
        name:"Travel Constraints",
        icon:edit,
        buttonName:"Edit",
        venue:"Venue",
        hiden:{
            display:"none"
        },
        style:{
            width:"91px",
            height:"44px"
        },
        goodison:"Goodison Park"
    },
    {
        name:"Time and Date Constrains",
        icon:plus,
        buttonName:"Add",
        Modal:Add,
        style:{
            width:"92px",
            height:"44px"
        },
        hiden:{
            display:"none"
        },
        image:img
        
    },
    {
        name:"Travel Constraints",
        icon:edit,
        buttonName:"Edit",
        HomeVenue:"Home Venues",
        venue:"Venue",
        Modal:EditModal,
        hiden:{
            display:"none"
        },
        style:{
            width:"91px",
            height:"44px"
        },
        goodison:"Goodison Park"
    },


]