import React from 'react'
import plus from "../../../../images/venues/plus.svg"
import style from "../style/edit.module.css";
const EditPagesFooter = ({isChecked,setIsChecked,venue,onChange}) => {
  return (
    <div className={style.editPagesFooter}>

<div className={style.checkboxInput}>

<input type="checkbox" checked={isChecked} onChange={()=>setIsChecked(prev=>!prev)} />

<span>Let all teams use this Venue as home teams, even if explicit home teams are assigned </span>
</div>
        <div className={style.venuesButton}>
            <span> Venues Playing Surfaces:3 </span>
            <button> <img src={plus} alt="plus" /> Add new </button>
        </div>

        
            <input type="text" data-id="playing_surface"  value={venue.playing_surface} className={style.input} onChange={onChange}/>
        
    </div>
  )
}

export default EditPagesFooter