import React from 'react'
import point from "../../images/admin/3point.png"
import bottom from "../../images/admin/bottom.png"
import redline from "../../images/admin/redline.png"
const AdminTotalTeams = () => {
  return (
    <div className='total-divisions'>
    <div className='text-total-divisions'>
     <span> Total Teams </span>
     <img src={point} alt="point" width={1.67} height={13.3} />
       </div>
      
       <div className='divisions-footer'>
            <div>
              <span className='text-number'>1210</span>
            <div className='text-box'><img src={bottom} alt="bottom" />
            <span className='pracent'>40% </span>
            <span>vs last month</span></div>
            </div>
            <img src={redline} alt="redline" className='text-line' />
         </div>
    </div>
  )
}

export default AdminTotalTeams