import schedule from "../../../images/admin/schedule.svg";
import division from "../../../images/admin/division.svg";
import team from "../../../images/admin/team.svg";
import venue from "../../../images/admin/venue.svg";
import person from "../../../images/admin/person.svg";
import league from "../../../images/admin/league.svg";
import season from "../../../images/admin/season.svg";
import svc from "../../../images/admin/import.svg";
export const checkboxData = [
  {
    name: "Schedule",
    img: schedule,
  },
  {
    name: "Division",
    img: division,
    link: "/divisions/create",
  },
  {
    name: "Team",
    img: team,
    link: "/divisions/create/team",
  },

  {
    name: "Venue",
    img: venue,
    link:"/create/venues"
  },

  {
    name: "Person",
    img: person,
  },

  {
    name: "League (Wizard)",
    img: league,
  },

  {
    name: "League (Manual)",
    img: league,
  },
  {
    name: "Season",
    img: season,
    link:"/season"

  },
  {
    name: "CSV Import",
    img: svc,
  },
];
