import React from "react";
import style from "../style/createVenuesStyle.module.css";
const CreateVenuesForms = ({ venues, handleChange }) => {
  return (
    <div className={style.createVenuesForm}>
      <div className={style.inputVenue}>
     
        <span> Name </span>
        <input
          type="text"
          data-id="name"
          value={venues.name}
          placeholder="Venue 1 "
          onChange={handleChange}
        />
      </div>

      <div className={style.addressForm}>

<div className={style.addressInput}>
    <span> Address line 1</span>
    <input type="text" data-id='first_address' value={venues.first_address} placeholder='Address' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> Address line 2</span>
    <input type="text" data-id='second_address' value={venues.second_address} placeholder='Address' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> City</span>
    <input type="text" data-id='city' value={venues.city} placeholder='City' onChange={handleChange}/>
</div>


</div>

<div className={style.addressForm}>

<div className={style.addressInput}>
    <span> State</span>
    <input type="text" data-id='state' value={venues.state} placeholder='State'onChange={handleChange} />
</div>

<div className={style.addressInput}>
    <span> Zip Code</span>
    <input type="text" data-id='zip_code' value={venues.zip_code} placeholder='Zip Code' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> Country</span>
    <input type="text" data-id='country' value={venues.country} placeholder='Country' onChange={handleChange}/>
</div>


</div> 


 <div className={style.addressForm}>

<div className={style.addressInput}>
    <span> Latitude</span>
    <input type="text" data-id='latitude' value={venues.latitude} placeholder='Latitude' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> Longitude</span>
    <input type="text" data-id='longitude' value={venues.longitude} placeholder='Longitude' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> Google map link</span>
    <input type="text" data-id='google_map_link' value={venues.google_map_link} placeholder='Google map link' onChange={handleChange}/>
</div>
</div>

<div className={style.phoneForm}>

<div className={style.addressInput}>
    <span> Prmary phone</span>
    <input type="text" data-id='primary_phone' value={venues.primary_phone} placeholder='Prmary phone' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> Secondary phone number</span>
    <input type="text" data-id='secondary_phone' value={venues.secondary_phone} placeholder='Secondary phone number' onChange={handleChange}/>
</div>







</div> 


<div className={style.phoneForm}>

<div className={style.addressInput}>
    <span> Prmary Email</span>
    <input type="email" data-id='primary_email' value={venues.primary_email} placeholder='Prmary email' onChange={handleChange}/>
</div>

<div className={style.addressInput}>
    <span> Secondary phone number</span>
    <input type="email" data-id='secondary_email' value={venues.secondary_email} placeholder='Secondary email'onChange={handleChange} />
</div>







</div> 



    </div>
  );
};

export default CreateVenuesForms;
