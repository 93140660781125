import React from "react";
import greenplus from "../../../.././../images/division/greenplus.svg";
import style from "./style/addStyle.module.css";
const AddHeader = () => {
  return (
    <div className={style.addHeader}>
      <div className={style.addTime}>
        <div className={style.addIcon}>
          {" "}
          <img src={greenplus} alt="greenplus" />{" "}
        </div>
        <span>Add Time and Date Constraints </span>

        <p> Choose Constraint Style</p>
      </div>

      <div className={style.weekly}>
        <div className={style.round}>
          <span className={style.label} >
            <input type="checkbox" className={style.checkbox} />
            <span className={style.weeklyText}>Weekly Constraint</span>
          </span>
        </div>

        <div className={style.round}>
          <span className={style.label} >
            <input type="checkbox" />
            <span className={style.weeklyText}>Specific Date</span>
          </span>
        </div>

        <div className={style.round}>
          <span className={style.label} >
            <input type="checkbox" />
            <span className={style.weeklyText}>Date Range</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddHeader;
