import React, {useCallback, useState} from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {divisionModalStyle} from "./style/divisionModalStyle"
import DivisionModalEditHeader from './DivisionModalEditHeader';
import DivisionModalEditMiddle from './DivisionModalEditMiddle';
import ChooseColor from './ChooseColor';
import DivisionModalEditFooter from './DivisionModalEditFooter';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import {DivisionAdd} from "../../../../component/features/slice/divisionSlice/divisionApi/divisionApi";
const DivisionModal = ({open}) => {
  const [data, setData] = useState({
    name:"",
  abbreviation:"",
  customCode:"",
  color:""

  })
  const dispatch = useDispatch()
  const handleDivision = () => {
    dispatch(DivisionAdd({data}))
    navigate("/divisions")
  }
  const handleChangeColor = (color) => setData({...data, color})
  const handleChangeInput = (e) => {
    const {value,dataset} = e.target;
    setData({...data,[dataset.id]:value})
     
  }
  const navigate = useNavigate()

  const handleClose =()=>{
    navigate("/divisions")
  }
  return (
    <Modal
    open={true}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...divisionModalStyle }}>
     <DivisionModalEditHeader />
     <DivisionModalEditMiddle data={data} onChange={handleChangeInput} />
     <ChooseColor chooseHandleColor={handleChangeColor} />
    <DivisionModalEditFooter handleClose={handleClose} handleSubmit={handleDivision}/>
  
    </Box>
  
  </Modal>
  )
}

export default DivisionModal