import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { addCoachModalStyle } from './style/addCoachModalStyle';
import AddCoachModalHeader from './AddCoachModalHeader';
import AddCoachModalMidle from './AddCoachModalMidle';
import CreateNewCoach from './CreateNewCoach';
import AddCoachModalFooter from './AddCoachModalFooter';
const AddCoachModal = ({open,handleClose}) => {
  return (
    <Modal
    open={open}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...addCoachModalStyle}}>
      <AddCoachModalHeader />
      <AddCoachModalMidle />
      <CreateNewCoach />
      <AddCoachModalFooter  handleClose={handleClose}/>
    </Box>
  </Modal>
  )
}

export default AddCoachModal