import React from 'react'
import style from "../../style/aboutSchedules.module.css"
import AboutSchedulesBottom from './AboutSchedulesBottom'
import AboutSchedulesFooterHeader from './AboutSchedulesFooterHeader'
import AboutSchedulesScroll from './AboutSchedulesScroll'
const AboutSchedulesFooter = () => {
  return (
    <div className={style.aboutSchedulesFooter}>
        <AboutSchedulesFooterHeader />
        <AboutSchedulesBottom />
        <AboutSchedulesScroll />
    </div>
  )
}

export default AboutSchedulesFooter