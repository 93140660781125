import React from "react";
import date from "../../../../../images/schedules/date.svg";
import style from "../../style/aboutSchedules.module.css"
const ScheduleDivision = () => {
  return (
    <div className={style.schedulesDivision}>
      <div className={style.schedulesDivisionContainer}>
        <div className={style.divisonTextName}>
          <span> Division Name</span>
          <span> Division name </span>
        </div>
        <div className={style.divisionDate}> <img src={date} alt="date" />  <span> 10/28/12 - 9/18/16 </span>  </div>
        <div className={style.divisionGame}> 
        <span> 2 Games per Team per Week </span>
        <p> Max 1 Games per day-Max 2 Games per Week </p>
         </div>
      </div>
    </div>
  );
};

export default ScheduleDivision;
