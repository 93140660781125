
export const choise = [
 
    {
        name:"Reset Filters",
        style:{
            width:"106px",
            height:"40px",
        },
     
    },

    {
        name:"Show timeslots",
        style:{
            width:"136px",
            height:"40px",
        },     
    },

    {
        name:"Create Game",
        style:{
            width:"121px",
            height:"40px",
        },     
    },

    {
        name:"Check Conflicts",
        style:{
            width:"140px",
            height:"40px",
        },     
    },

    {
        name:"Show Balances",
        style:{
            width:"135px",
            height:"40px",
        },     
    },

    {
        name:"Export",
        style:{
            width:"77px",
            height:"40px",
        },     
    },


      
]