import React from 'react'
import style from "../style/createVenuesStyle.module.css"
import plus from "../../../../images/venues/plus.svg"
const CreateVenuesFooter = ({isChecked,setIsChecked,onChange,venue}) => {
  
 
  return (
    <div className={style.createVenuesFooter}>
<div className={style.checkboxInput}>
<input type="checkbox" checked={isChecked} onChange={()=>setIsChecked(prev=>!prev)} />

<span>Let all teams use this Venue as home teams, even if explicit home teams are assigned </span>

  </div>
  <div className={style.venuesPlaying}>
  <span> Venues Playing Surfaces:3</span>
  <button > <img src={plus} alt='plus' /> Add new </button>
  </div>
  <div className={style.surface}>
           <input type='name' placeholder='Surface' data-id="playing_surface" value={venue.playing_surface} onChange={onChange} />

    </div>
    </div>
 
  )
}

export default CreateVenuesFooter






