
import React from 'react'
import CalendarFooter from './calendarPages/CalendarFooter'
import CalendarHeader from './calendarPages/CalendarHeader'
import CalendarMiddle from './calendarPages/CalendarMiddle'
import styles from "./style/calendarStyle.module.css"
const Calendar = () => {
  return (
    
    <div className={styles.calendarMain}>
      <CalendarHeader />
      <CalendarMiddle />
      <CalendarFooter />
    </div>
  )
}

export default Calendar