import React from 'react'
import search from "../../images/admin/search.svg"
const AdminSerachInput = () => {
  return (
    <div className='search'>
      <span className="search-icon">
        <img src={search} alt="seach"/>
      </span>
        <input type="text" placeholder='search'  />
</div>
  )
}

export default AdminSerachInput