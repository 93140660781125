import React from "react";
import style from "./style/view.module.css";
import img from "../../../images/venues/image.jpeg";
import plus from "../../../images/venues/plus.svg";

const ViewFooter = () => {
  return (
    <div className={style.viewFooter}>
      <div className={style.note1}>
        <div className={style.noteText}>
          <span> Note 1 </span>
          <button>
          
            <img src={plus} alt="plus" /> Add new
          </button>
        </div>


      </div>

      <div className={style.note1}>
      
    

<div className={style.noteText}>
          <span> Note 2</span>
          <button>
          
            <img src={plus} alt="plus" /> Add new
          </button>
        </div>

        <div className={style.goodison}>
            <p> Venue </p>
            <span> Goodison Park</span>

        </div>
      </div>
    </div>
  );
};

export default ViewFooter;
