import React from 'react'
import style from "./style/addStyle.module.css"
const AddFotter = () => {
  return (
    <div className={style.addFooter}>
              <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>NoPlay All Day</span>
          </span>
        </div>

        <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>NoPlay Time Range</span>
          </span>
        </div>

        <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>Max Games Limit</span>
          </span>
        </div>

        <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>Max Travel from Home</span>
          </span>
        </div>

        <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>Max Travel From Last</span>
          </span>
        </div>

        <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>Must be at home</span>
          </span>
        </div>


        <div className={style.noPlay}>
          <span className={style.label}>
            <input type="checkbox" className={style.checkbox} />
            <span className={style.NoPlayText}>Cannot play a team</span>
          </span>
        </div>

    </div>
  )
}

export default AddFotter