import React from 'react'
import style from "../schedulesstyle/schedules.module.css"
import SchedulesUploadCv from './SchedulesUploadCv'
const SchedulesHeader = () => {
  return (
    <div className={style.schedulesHeader}>
        <div className={style.schedulesText}> <span> Schedules </span></div>
        <SchedulesUploadCv />
    </div>
  )
}

export default SchedulesHeader