import React from 'react'
import logoCompany from "../../images/companymenu/logocompany.png"
const CompanyName = () => {
  return (
    <div className='header-company-name'>
        <img src={logoCompany} alt="logocompany"/>
        <span className='text-company-name'> Company Name </span>
    </div>
  )
}

export default CompanyName