import { configureStore } from "@reduxjs/toolkit";
import {loginSliceReducer} from "../slice/loginSlice/loginSlice";
import {divisionSliceReducer} from "../slice/divisionSlice/divisionSlice";
import { venueSliceRedcer } from "../slice/venuesSlice/venuesSlice";
import { calendarSliceReduce } from "../slice/calendarSlice/calendarSlice";


export const store = configureStore({
    reducer:{
        hockey:loginSliceReducer,
        division:divisionSliceReducer,
        venue:venueSliceRedcer,
        calendar:calendarSliceReduce
    }
})