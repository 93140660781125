import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { divisionData } from "../divisionData/divisionData";
import style from "../style/divisionStyle.module.css";

const DivisionMiddle = () => {
  const navigate = useNavigate()
  const handleOpen = () => navigate('/divisions/create');
  const handleCreate = () => navigate("/divisions/create/team")

  

  return (
    <div className={style.divisionMidle}>
      {divisionData.map((item, index) => (
        <div key={index} className={style.settingsDivision}>
          <div className={style.divisionTitle}>
            <span> {item.name} </span>
            <button className={style.buttonEdit} onClick={handleOpen}>

              <img src={item.icon} alt={item.name} /> Edit
            </button>
          </div>

          <div className={style.manage}>
            <span> {item.teams}</span>
           <Link to="/manage"> <button className={style.buttonManage}> Manage </button>  </Link>  
          </div>

          <div className={style.teamNameImage}>
            <span> {item.teamsName}</span>

            <div className={style.infoImage}>
                    <img src={item.image} alt={item.name} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-10px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-20px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-30px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-40px"}} className={style.divisionAccountImage}/>
                    <div className={style.imageLength}>{item.length}</div>
            </div>
          </div>

          <div className={style.teamNameImage}>
            <span> {item.teamsName}</span>

            <div className={style.infoImage}>
                    <img src={item.image} alt={item.name} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-10px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-20px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-30px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-40px"}} className={style.divisionAccountImage}/>
                    <div className={style.imageLength}>{item.length}</div>
            </div>
          </div>


          <div className={style.teamNameImage}>
            <span> {item.teamsName}</span>

            <div className={style.infoImage}>
                    <img src={item.image} alt={item.name} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-10px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-20px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-30px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-40px"}} className={style.divisionAccountImage}/>
                    <div className={style.imageLength}>{item.length}</div>
            </div>
          </div>

          <div className={style.teamNameImage}>
            <span> {item.teamsName}</span>

            <div className={style.infoImage}>
                    <img src={item.image} alt={item.name} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-10px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-20px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-30px"}} className={style.divisionAccountImage}/>
                    <img src={item.image} alt={item.name} style={{position:"relative",left:"-40px"}} className={style.divisionAccountImage}/>
                    <div className={style.imageLength}>{item.length}</div>
            </div>

           
          </div>
          <div className={style.schedulesCreate}> <span> {item.schedules} </span> <button onClick={handleCreate}> Create </button> </div>
          <div className={style.scheduleNames}> <span> {item.scheduleName} </span>  <span> {item.scheduleName} </span>   </div>
        
        </div>
      ))}
    </div>
  );
};

export default DivisionMiddle;
