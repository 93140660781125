import style from "../style/season.module.css"
import React from 'react'
import download from "../../../../images/season/download.svg"

const CreateLeagueForm = ({nextPage,canclePage}) => {
  
  return (
    <div className={style.createForm}>

        <div className={style.leagueInput}>
            <span> League name </span>
            <input type ="text" placeholder="League name" />
        </div>

        <div className={style.leagueInput}>
            <span> Season name </span>
            <input type ="text" placeholder="Season name" />
        </div>

        <div className={style.upload}>
            <button> <img src={download} alt="download" /> Upload Divisions via CSV </button>
        </div>


        <div className={style.buttonsLeague}>
            <button className={style.continue} onClick={nextPage} > Continue</button>
            <button className={style.cancel} onClick={canclePage}> Cancel </button>
        </div>



    </div>
  )
}

export default CreateLeagueForm