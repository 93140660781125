import React from "react";
import style from "../../style/aboutSchedules.module.css"
import { date } from ".././aboutSchedulesData/date"
const SchedulesCheckbox = () => {
  return (
    <div className={style.schedulesCheckBox}>
      {date.map((item, index) => (
        <div key={index}className={style.ss}>
           <input type="checkbox" />
           <span> {item.name} </span> 
        </div>
      ))}
    </div>
  );
};

export default SchedulesCheckbox;
