export  const createCoachStyle = {
  position: "absolute",
  width:"713px",
  height:"698px",
  background:"#FFFFFF",
  left:"363px",
  top:"80px",
  padding: "24px"


};
