import React from 'react'
import style from "../style/seasonTeam.module.css"
const SeasonNameTitle = () => {
  return (
    <div className={style.seasonName}>
        <div className={style.seasonNameTitle}>
            <span> Team names </span>
            <span> Abbrevitation </span>

        </div>

            <div className={style.container}>

            <div className={style.teamInputs}> 
            <input type="text" placeholder='Division1' className={style.teamDivision}/>
            <input type="text" placeholder='D1' className={style.numberDivison}/>
        </div>

        <div className={style.teamInputs}> 
            <input type="text" placeholder='Division1' className={style.teamDivision}/>
            <input type="text" placeholder='D1' className={style.numberDivison}/>
        </div>

        <div className={style.teamInputs}> 
            <input type="text" placeholder='Division1' className={style.teamDivision}/>
            <input type="text" placeholder='D1' className={style.numberDivison}/>
        </div>

        <div className={style.teamInputs}> 
            <input type="text" placeholder='Division1' className={style.teamDivision}/>
            <input type="text" placeholder='D1' className={style.numberDivison}/>
        </div>

            </div>

            
       

    </div>
  )
}

export default SeasonNameTitle