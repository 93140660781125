import React from "react";
import done from "../../../../../../images/schedules/done.svg";
import circle  from "../../../../../../images/schedules/circle.svg"
import writecircle from "../../../../../../images/schedules/writecircle.svg"
import style from "../style/retryModal.module.css";

const RetryModalHeader = () => {
  return (
    <div className={style.retryModalHeader}>
      <div className={style.retryModalIcon}>
        <img src={done} alt="done" />
      </div>
      <div className={style.retryTitle}>
        <span> Retry Schedule</span>
        <p>
        
          Lorem ipsum dolor sit amet consectetur. Auctor orci lorem malesuada
          ultricies enim lobortis quam viverra.
        </p>
      </div>
      <div className={style.choiceGames}>
        <div className={style.allGames}> <img src={circle} alt="circle" /> <span>  All games</span>  </div>
        <div className={style.unScheduled}>
        <img src={writecircle} alt="writecircle" /> <span>  Unscheduled Games </span>  </div>
             </div>
             
      </div>
 
  );
};

export default RetryModalHeader;
