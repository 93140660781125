import React from 'react'
import DetailsCalendar from './detailsPage/DetailsCalendar'
import DetailsForm from './detailsPage/DetailsForm'
import DetailsPersonalInfo from './detailsPage/DetailsPersonalInfo'
import style from "./style/details.module.css"
const MyDetails = () => {
  return (
    <div className={style.myDetails}>

      <DetailsPersonalInfo />
      <DetailsForm />
      <DetailsCalendar />
    </div>
  )
}

export default MyDetails