import React from "react";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { SendCalendarDate } from "../../../../component/features/slice/calendarSlice/calendarApi/calendarApi";
const CalendarFooter = () => {
  const [events, setEvents] = useState([]);

  const dispatch = useDispatch();
  const handleSelect = (info) => {
    const { start, end } = info;
    let date = start;

    date = new Date();

    const eventNamePrompt = prompt("Enter, event name");
    if (eventNamePrompt) {
      const newDate = [
        ...events,
        {
          date,
          end,
          title: eventNamePrompt,
          id: uuid(),
        },
      ];
      setEvents(newDate);

      dispatch(SendCalendarDate(newDate));
    }
  };

  const handleData = (events) => {
    //  const id = events.map((elem)=>elem.id)
    //  id.splice(0,1)
    //   return events
    //   // if(id.length === 1){
    //   //  id.slice(0,1)
    //   // }
    //   // else{
    //   // return false
    //   // }
  };
  return (
    <div>
      <FullCalendar
        editable
        selectable
        events={events}
        contentHeight={"400px"}
        select={handleSelect}
        dateClick={() => handleData(events)}
        headerToolbar={{
          start: "today prev next",
          end: "dayGridMonth dayGridWeek dayGridDay",
        }}
        plugins={[daygridPlugin, interactionPlugin]}
        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
      />
    </div>
  );
};

export default CalendarFooter;
