import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { editModalStyle } from './style/editModalStyle';
import EditModalHeader from './EditModalHeader';
import EditModalMiddle from './EditModalMiddle';
import EditModalFooter from './EditModalFooter';
import EditButtons from './EditButtons';
const EditModal = ({open,handleClose}) => {
  return (
    <Modal
    open={open}      
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box sx={{...editModalStyle}}>
    <EditModalHeader />
    <EditModalMiddle />
    <EditModalFooter />
    <EditButtons handleClose={handleClose} />
    </Box>
  </Modal>
  )
}

export default EditModal