import React from "react";
import style from "../style/calendarStyle.module.css";
import CalendarHeaderLastSelect from "./CalendarHeaderLastSelect";
import CalendarMiddleSelect from "./CalendarMiddleSelect";
const CalendarMiddle = () => {
  return (
    <div className={style.calendarMiddle}>
      <div className={style.calendarChoose}>
      <span>Choose Schedule </span>
        <select>
          <option >Schedule name</option>
        </select>
        <div className={style.calendarSurfaces}>
    <span>Surfaces </span>
        <select>
          <option > Divison 1</option>
        </select>
    </div>

      </div>
      <div>
    
  
    
      </div>
    <CalendarMiddleSelect />
    <CalendarHeaderLastSelect />
    </div>
  );
};

export default CalendarMiddle;
