import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { seasonModalStyle } from './style/seasonModallStyle';
import CreateLeague from './seasoneCreate/CreateLeague';
import CreateLeagueForm from './seasoneCreate/CreateLeagueForm';
import { useNavigate } from 'react-router-dom';
const Season = () => {
  const navigate = useNavigate()

  const nextPage = () => {
    navigate("/create/season")
  }
  const canclePage = () =>{
    navigate("/admin")
  }
  return (
    <Modal
    open={true}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...seasonModalStyle }}>
    <CreateLeague />
    <CreateLeagueForm  nextPage={nextPage} canclePage={canclePage}/>
    </Box>
  
  </Modal>
  )
}

export default Season