import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createCoachStyle } from "./style/createCoachStyle";
import CoachModal from "./CoachModal";
import CoachModalCheckbox from "./CoachModalCheckbox";
import CoachModalForm from "./CoachModalForm";
import CoachModalUpload from "./CoachModalUpload";
import CoachModalFooter from "./CoachModalFooter";
import { useDispatch } from "react-redux";
import { CreateCoach } from "../../../../../../component/features/slice/divisionSlice/divisionApi/divisionApi";
import { useNavigate } from "react-router-dom";
const CreateCoachModal = ({ open, handleClose }) => {
  const [coach, setCoach] = useState({
    first_name: "",
    last_name: "",
    person_role: "",
    home_phone: "",
    work_phone: "",
    primary_email: "",
    secondary_email: "",
  });

  const [csv, setCsv] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCoach({ ...coach, [e.target.name]: e.target.value });
  };

  const handleCreateCoach = () => {
    if (csv) {
      const person_role = coach.person_role;

      const data = { person_role, csv };

      dispatch(CreateCoach(data))
        .unwrap()
        .then((res) => {
          if ((res.message = "Created")) {
            navigate("/divisions");
          }
        });
    } else {
      dispatch(CreateCoach(coach))
        .unwrap()
        .then((res) => {
          if ((res.message = "Created")) {
            navigate("/divisions");
          }
        });
    }
  };
  const handleChangeFile = (e) => {
    setCsv(e.target.files[0]);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...createCoachStyle }}>
        <CoachModal coach={coach} onChange={handleChange} />
        <CoachModalCheckbox coach={coach} onChange={handleChange} />
        <CoachModalForm coach={coach} onChange={handleChange} />
        <CoachModalUpload onChange={handleChangeFile} />
        <CoachModalFooter
          handleClose={handleClose}
          onClick={handleCreateCoach}
        />
      </Box>
    </Modal>
  );
};

export default CreateCoachModal;
