import React from 'react'

const NextPreviusPage = () => {
  return (
    <div className='next-previus-page'>
        <div className='button-page'> 
            <button className='button-priveus'> Previus </button>
            <button className='button-next'> Next </button>
             </div>
             <div className='list-page'> <span> Page 1 of 10 </span> </div>
    </div>
  )
}

export default NextPreviusPage