import React from 'react'
import style from "../style/selectDivision.module.css"
import PlusPage from '../../plusPage/PlusPage'
const SelectDivisionTitle = () => {
  return (
    <div className={style.selectTitle}>
        <PlusPage />

        <div className={style.textTittle}>
            <h1>Create League and Season </h1>
            <span>  How many teams per divisions? </span>
            <p> Please note maximal divisions number is 30</p>
            
        </div>

    </div>
  )
}

export default SelectDivisionTitle