import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { addStyle } from './style/addStyle';
import AddHeader from './AddHeader';
import AddMiddle from './AddMiddle';
import AddFotter from './AddFotter';
import Apply from './Apply';
import AddSaveCancle from './style/AddSaveCancle';


const Add = ({open,handleClose}) => {
  return (
    <Modal
        open={open}      
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...addStyle }}>
        <AddHeader />
        <AddMiddle />
        <AddFotter />
        <Apply />
        <AddSaveCancle handleClose={handleClose}/>
        </Box>
      </Modal>
  )
}

export default Add