import React from 'react'
import filters from "../../../images/calendar/filters.svg"
import style from "../style/calendarStyle.module.css"
const CalendarHeader = () => {
  return (
    <div className={style.calendarHeader}>
        <div className={style.calendarText}> Calendar </div>
        <div className={style.calendarHeaderButtons}>
            <button className={style.calendarMoreFiltersButton}> <img src={filters} alt="filters" /> <span> More filters</span>  </button> 
            <button className={style.calendarResetButton}> Reset Filtters </button> 
            <button className={style.selectAllButton}> Select all </button> 
             </div>
    </div>
  )
}

export default CalendarHeader