import React from 'react'
import style from "./style/divisionModalStyle.module.css"
import done from "../../../images/division/done.svg"
const DivisionModalEditHeader = () => {
  return (
    <div className={style.divisionModalEditHeader}>
        <div className={style.divisonDoneImage}> <img src={done} alt="done" /> </div>
        <div className={style.editText}>
            <span> Edit/Create Division </span>
            <p> Lorem ipsum dolor sit amet consectetur. Auctor orci lorem malesuada ultricies enim lobortis quam viverra.  </p>
        </div>
    </div>
  )
}

export default DivisionModalEditHeader