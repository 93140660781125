import React from 'react'
import style from "./style/editModal.module.css"
const EditModalMiddle = () => {
  return (
    <div className={style.editModalMiddle}>

    <span> Type here new name </span>
    <input type="text"  placeholder='Schedule name'/>

        
    </div>
  )
}

export default EditModalMiddle