import React from 'react'
import GameListFooter from './gameListPages/GameListFooter'
import GameListHeader from './gameListPages/GameListHeader'
import GameListMiddle from './gameListPages/GameListMiddle'
import GameListScrool from './gameListPages/GameListScrool'
import GameListSearch from './gameListPages/GameListSearch'
import GamePageBallances from './gameListPages/GamePageBallances'
import GamePageNextPrev from './gameListPages/GamePageNextPrev'
import style from "./style/gameListStyle.module.css"
const GameList = () => {
  return (
    <div className={style.gameList}>
      <GameListHeader/>
      <GameListMiddle />
      <GameListSearch />
      <GameListFooter />
      <GameListScrool />
      <GamePageNextPrev />
      <GamePageBallances />

    </div>
  )
}

export default GameList