import React from 'react'
import style from "../style/createVenuesStyle.module.css"
const CreateVenuesMiddle = ({venues,handleChange}) => {
  return (
    <div className={style.createVenuesMiddle}>
        <div className={style.priority}> 
            <span> Venue Priority</span>
            <input type="number" data-id="priority" value={venues.priority} onChange={handleChange} />

        </div>


        <div className={style.priority}>
            <span> Venues notes </span>
        <textarea  className={style.textarea} data-id="notes" value={venues.notes} onChange={handleChange}></textarea>
        </div>
      

    </div>
  )
}

export default CreateVenuesMiddle