import React  from "react";
import style from "../style/venuesStyle.module.css";
import "../style/table.css";

import { useDispatch } from "react-redux";


import { useNavigate } from "react-router-dom";
import { editVenue } from "../../../../component/features/slice/venuesSlice/venuesSlice";
const VenueTable = ({allVenues}) => {




  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleOpen = ({id}) => {

    dispatch(editVenue(id))
    navigate(`/editpage#${id}`)
  };
  return (
    <div className={style.venueTable}>

      {allVenues  && allVenues.map(
        ({ id,name,first_address, primary_phone,playing_surface}) => (
          <div key={id} className="table">
            <div className="vanueName">
              <span> {name} </span>
              <button
                className={style.viewEdit}
                onClick={() => handleOpen({id})}
              >
                
                View
              </button>
            
             
              
            </div>

            <div className="address">
              <span> {first_address}</span>

              <span> {primary_phone}</span>
            
              <span> {playing_surface} </span>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default VenueTable;
