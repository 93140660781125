import style from "./createNewStyle/creeateNewStyle.module.css"
import download from "../../../images/division/download.svg"
const CreateNewTeamFooter = ({handleCreate,handleCancle}) => {
    return(
        <div className={style.createNewTeamFooter}>
            <div className={style.createSize}> <button className={style.createUpload}> <img src={download} alt="download" /> Upload Divisions via CSV </button></div>
            <div className={style.saveCancle}>
                <button className={style.cancleButton} onClick={handleCancle} > Cancle</button>
                <button className={style.saveButton} onClick={handleCreate}> Save</button>

            </div>

        </div>
    )
}

export default  CreateNewTeamFooter