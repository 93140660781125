import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { gameModalstyle } from "./style/gameModalStyle";
import GameModalPagesHeader from "./GameModalPagesHeader";
import GameModalTable from "./GameModalTable";
import GameModalVenueBalance from "./GameModalVenueBalance";
import GameModalFooter from "./GameModalFooter";

const GameModalPages = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...gameModalstyle }}>
       
        <GameModalPagesHeader />
        <GameModalTable />
        <GameModalVenueBalance />
        <GameModalFooter />
      </Box>
    </Modal>
  );
};

export default GameModalPages;
