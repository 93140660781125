import React from "react";
import style from "../style/gameListStyle.module.css";
import GameListTableData from "./GameListTableData";

const GameListFooter = () => {
  return (
    <div className={style.gameListFooter}>
      <GameListTableData />
 
    
    </div>
  );
};

export default GameListFooter;
