import React from 'react'
import style from "./style/manage.module.css"
import plus from "../../../images/settings/plus.svg"
import { leagueTable } from './managePages/manageData/leagueTable'
const ManageLeagues = () => {
  return (
    <div className={style.manageLeagues}>
         <div className={style.leagues}>
        <span> Leagues: number of leauges </span>

        <div className={style.addNew}>
          <button> <img src={plus} alt="plus" /> Add New</button>

        </div>
   
    </div>


    <div className={style.leagueTable}>

      {
        leagueTable.map((item,index)=>(
          <div key={index} className={style.tableSize}>
            <div className={style.leagueName}>
            <span> {item.name}</span>
            <button> View</button>

              </div>

              <div className={style.season}>
                <span> {item.seasoneNumber}</span>
                <span>  {item.seasonePeriod}</span>
              
              </div>



              <div className={style.buttons}>

                <button className={style.download}> Download </button>
                <button className={style.edit}> Edit </button>
                <button className={style.detete}> Delete </button>

                </div>
           


            </div>
        ))
      }
      

    </div>
    </div>

  )
}

export default ManageLeagues