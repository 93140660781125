import React from 'react'
import style from "../style/seasonTeam.module.css"
import styles from "../../createSeason/style/create.module.css"
const SeasonFooter = ({cancelPage}) => {
  return (
    <div>
            <div className={style.divisonNameTable}>
           <div className={style.divisonTableTitle}>
                <div className={style.divisionColor}>
                    <div></div>
                    <span>Divison name</span>
                </div>

                <div className={style.abbrevitationPlus}>
                    <span> Abbrevitation</span>
                    <div> <span> + </span> </div>

                </div>
                
            </div>
            <div className={style.divisionInput}>
                <input type="text" placeholder='Division1' className={style.teamDivision}/>
                <input type="text" placeholder='D1'  className={style.numberDivison}/>

            </div>

    </div>


    <div className={style.buttonsTable}>
        <button className={styles.continue}> Continue </button>
        <button className={styles.cancel}  onClick={cancelPage}> Cancel </button>
    </div>

    </div>

  )
}

export default SeasonFooter