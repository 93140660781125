import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import settings from "../../images/companymenu/settings.png"
import "../style/companyMenu.css"
const CompanySettings = () => {
  const { pathname } = useLocation()
  return (
    <div className={`company-settings ${pathname === "/settings" && 'active'}`}>
      <Link className="settings" to="/settings">
      <img src={settings} alt="settings" width={22} height={22}/>
        <span style={{color:"white"}}> Settings</span>
      </Link>
     
    </div>
  )
}

export default CompanySettings