import home from "../../../images/companymenu/home.png"
import schedules from "../../../images/companymenu/schedules.png"
import calendar from "../../../images/companymenu/calendar.png"
import gameList from "../../../images/companymenu/gamelist.png"
import divisions from "../../../images/companymenu/divisions.png"
import venues from "../../../images/companymenu/venues.png"
import people from "../../../images/companymenu/people.png"
export const dataCompany = [
    {   id:1,
        image:home,
        href: "/admin",
        title:"Home"
    },
    {   id:2,
        image:schedules,
        href: "/schedules",
        title:"Schedules"
    },
    {   id:3,
        image:calendar,
        href:"/calendar",
        title:"Calendar"
    },
    {   id:4,
        image:gameList,
        href:"/gamelist",
        title:"Game List"
    },
    {   id:5,
        image:divisions,
        href:"/divisions",
        title:"Divisions"
    },
    {   id:6,
        image:venues,
        href:"/venues",
        title:"Venues"
    },
    {   id:7,
        image:people,
        href:"/people",
        title:"People"
    },
    
]