import React from 'react'
import style from "../style/forgotStyle.module.css"
const ForgotPasswordHeader = () => {
  return (
    <div className={style.forgotPasswordHeader}>
      <h1>Forgot Password?  </h1>
      <p> No worries, we’ll send you reset instructions</p>


    </div>
  )
}

export default ForgotPasswordHeader