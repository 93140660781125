import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { deleteModalStyle } from './style/deleteModalStyle';
import DeleteModalHeader from './DeleteModalHeader';
import DeleteButtons from './DeleteButtons';
const DeleteModal = ({showModal,handleModalClose}) => {
  return (
    <Modal
    open={showModal}
   
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...deleteModalStyle }}>

    <DeleteModalHeader />
    <DeleteButtons handleModalClose={handleModalClose} />
    </Box>
  </Modal>
  )
}

export default DeleteModal