import React from 'react'
import GameListScrool from '../gameListPages/GameListScrool'
import GameTable from './GameTable'
import GamePageNextPrev from '../gameListPages/GamePageNextPrev'
import style from "./style/gameModalStyle.module.css"
const GameModalTable = () => {
  return (
    <div className={style.gameModalTable}>

      <GameTable />
      <div className={style.gameTableScroll}>
      <GameListScrool />
      </div>
    <div>
   
    </div>
    
    <div className={style.tablePage}>
     <GamePageNextPrev /> 
    </div>
      




    </div>
  )
}

export default GameModalTable