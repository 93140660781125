import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import style from "../style/loginStyle.module.css";
import {LoginUer} from "../../../features/slice/loginSlice/loginApi/loginApi";
const LoginForm = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
    isChecked: false
  })
 
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value})
  };

  const handleToggle = ({ target: { checked } }) => {
    setData({
      ...data,
      isChecked: checked
    })
  }

  const handleClick = () => {
    dispatch(LoginUer({loginUser: data})).unwrap().then((response)=>{
      
      if(response){
         if(data.isChecked){
          
          localStorage.setItem("token", response.user.token)
          localStorage.setItem("firstname",response.user.first_name)
          localStorage.setItem("email",response.user.email)
         }
         else{
          sessionStorage.setItem("token",response.user.token )
          sessionStorage.setItem("email",response.user.email)
          sessionStorage.setItem("firstname",response.user.first_name)
         }
        navigate("/admin")
      }
    })
  }

  return (
    <div className={style.loginForm}>
      <div className={style.loginInputs}>
        <div className={style.welcome}>
      
          <span> Welcome Back! </span>
        </div>

        <div className={style.loginInputs}>
          <input
            type="text"
            name="email"
            value={data.email}
            placeholder="Email"
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            value={data.password}
            placeholder="Password"
            onChange={handleChange}
          />
          <div className={style.remember}>
            <input
              type="checkbox"
              name="isChecked"
              checked={data.isChecked}
              onChange={handleToggle}
            />
            <span> Remember me</span>
          </div>
          <Link className={style.forgot} to="/forgotpassword"> Forgot password </Link>

          <div className={style.loginButton}>
            <button onClick={handleClick}> Login </button>

            <span>
              You don't have account?
              <Link className={style.signUpLink} to="/registration">
                Sign up
              </Link>
            </span>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
