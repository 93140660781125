import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React, {useState} from "react";


import {createNewStyle} from "./createNewStyle/createNewStyle";
import CreateNewTeamHeader from "./CreateNewTeamHeader";
import CreateNewTeamMiddle from "./CreateNewTeamMiddle";

const CreateNewTeamModal = () => {
    const [createTeam, setCreateTeam] = useState({
            name:"",
            division_name:"",
            division_id:"",
            abbreviation:"",
            customCode:""

    })

    const handleChange = (e) => {
        const {value,dataset} = e.target;
        setCreateTeam({...createTeam,[dataset.id]:value})
    }
    return(
        <Modal
            open={true}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...createNewStyle }}>
        <CreateNewTeamHeader />
        <CreateNewTeamMiddle createTeam={createTeam} onChange={handleChange}/>
            </Box>

        </Modal>
    )
}

export default  CreateNewTeamModal