import React from 'react'
import style from "../style/gameListStyle.module.css"
import filter from "../../../images/gamelist/filters.png"
import { choise } from '../../schedules/aboutSchedules/aboutSchedulesPages/aboutSchedulesData/choise'
const GameListHeader = () => {
  return (
    <div className={style.gameListHeader}>
       Master Game list 
        <div className={style.gameFilters}>

        <button className={style.gameMoreFilters}>
        <img src={filter} alt="filter" /> <span>More Filters </span> 
      </button>
      {
        choise.map((item,index)=>(
            <div key={index}>
            <button style={item.style} className={style.gameListButtons}>
              {item.name}
            </button>
          </div>
        ))
      }

        </div>
    </div>
  )
}

export default GameListHeader