import React from "react";
import style from "./style/view.module.css";
import edit from "../../../images/venues/edit.svg";
import img from "../../../images/venues/image.jpeg";
import { Link } from "react-router-dom";
const ViewPageHeader = () => {
  return (
    <div className={style.viewPageHeader}>
      <span> Venue Name </span>

      <div className={style.venueTable}>
        <div className={style.vanueName}>
          <span> Venue Name</span>
          <Link to="/editpage"><img src={edit} alt="edit" width={20} height={20}/> Edit  </Link>
            
         
        </div>
        <div className={style.address}>
          <span> Address </span>
          <p> 1901 Thornridge Cir. Shiloh, Hawaii 81063 </p>
          <span> Phone </span>
          <p>(704) 555-0127 </p>
          <span>Playing Surfaces </span>
          <p> 4 </p>
          <span> Home Teams </span>
        </div>

        <div className={style.venueTeamName}>
          <span> Team name </span>
           <img src={img} alt="img"  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-10px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-20px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-30px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-40px" }}  className={style.imageSize}/>
           <div className={style.imageLength}> +5</div>
           

        </div>

        <div className={style.venueTeamName}>
          <span> Team name </span>
           <img src={img} alt="img"  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-10px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-20px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-30px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-40px" }}  className={style.imageSize}/>
           <div className={style.imageLength}> +5</div>
           

        </div>
        <div className={style.venueTeamName}>
          <span> Team name </span>
           <img src={img} alt="img"  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-10px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-20px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-30px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-40px" }}  className={style.imageSize}/>
           <div className={style.imageLength}> +5</div>
           

        </div>
        <div className={style.venueTeamName}>
          <span> Team name </span>
           <img src={img} alt="img"  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-10px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-20px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-30px" }}  className={style.imageSize}/>
           <img src={img} alt="img"   style={{ position: "relative", left: "-40px" }}  className={style.imageSize}/>
           <div className={style.imageLength}> +5</div>
           

        </div>
      </div>
    </div>
  );
};

export default ViewPageHeader;
