import axios from "axios";

export const $authHost = axios.create({
    baseURL: "https://5603.freelancedeveloper.site/api",
  });
  

  const authInterceptor = (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem("token") || sessionStorage.getItem('token')}`;
    return config;
  };
  $authHost.interceptors.request.use(authInterceptor);