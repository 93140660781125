import React from 'react'
import DivisionMiddle from './divisionsPage/DivisionMiddle'
import DivisionsHeader from './divisionsPage/DivisionsHeader'
import style from "./style/divisionStyle.module.css"
const Divisions = () => {
  return (
    <div className={style.divisionMain}>
      <DivisionsHeader />
      <DivisionMiddle />
      
    </div>
  )
}

export default Divisions