import React from 'react'

import style from "../style/update.module.css"
import { useNavigate } from 'react-router-dom'
const UpdateMessage = () => {
    const navigate =  useNavigate()
  return (
    <div className={style.message}>
        <span> Password Updated </span>
        <p>Your password has been updated!</p>

        <button className={style.login} onClick={()=>{navigate("/login")}} >  Login  </button> 
    </div>
  )
}

export default UpdateMessage