import React from "react";
import style from "../../style/aboutSchedules.module.css";
import filter from "../../../../../images/schedules/filters.png";
import { choise } from ".././aboutSchedulesData/choise"

const SheduleChoise = () => {
  return (
    <div className={style.scheduleChoise}>
      <button className={style.moreFilters}>
        <img src={filter} alt="filter" /> More Filters
      </button>
      {choise.map((item, index) => (
        <div key={index}>
          <button style={item.style} className={style.choiceButtons}>
            {item.name}
          </button>
        </div>
      ))}
    </div>
  );
};

export default SheduleChoise;
