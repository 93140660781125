import React from "react";
import style from "../../style/aboutSchedules.module.css";
const ScheduleSelectDivision = () => {
  return (
    <div className={style.scheduleSelectDivision}>
      <div className={style.choiceSelect}>
        <span> Divisions</span>
        <select>
          <option>Division 1</option>
        </select>
      </div>

      <div className={style.choiceSelect}>
        <span> Teams</span>
        <select>
          <option>Team name</option>
        </select>
      </div>
      <div className={style.choiceSelect}>
        <span> Venues</span>
        <select>
          <option>Venue name</option>
        </select>
      </div>
    </div>
  );
};

export default ScheduleSelectDivision;
