import React, { useEffect} from 'react'
import VenueHeader from './pages/VenueHeader'
import VenueTable from './pages/VenueTable'
import style from  "./style/venuesStyle.module.css"
import ViewFooter from './viewpage/ViewFooter'
import { useDispatch, useSelector } from 'react-redux'
import { getVenuDate } from '../../../component/features/slice/venuesSlice/venuesApi'
const Venues = () => {
 
  const dispatch = useDispatch()
  const { allVenues } = useSelector((state) => state.venue);



  useEffect(()=>{
      dispatch(getVenuDate())
  },[dispatch])
 
  return (
    <div className={style.venues}>
      <VenueHeader />
      <VenueTable allVenues={allVenues}/>
      <ViewFooter />
    </div>
  )
}

export default Venues