import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";


import { useNavigate } from 'react-router-dom';
import { seasonTeamStyle } from './style/seasonTeamStyle';
import SeasonTeamTitle from './seasonTeamPage/SeasonTeamTitle';
import SeasonNameTitle from './seasonTeamPage/SeasonNameTitle';
import SeasonFooter from './seasonTeamPage/SeasonFooter';
const SeasonTeamName = () => {
  const navigate = useNavigate()
  const cancelPage = () => {
    navigate("/admin")
  }
  return ( 
    <Modal
    open={true}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{...seasonTeamStyle }}>
        <SeasonTeamTitle />
        <SeasonNameTitle />
        <SeasonFooter  cancelPage={cancelPage}/>
    </Box>
  
  </Modal>
  )
}

export default SeasonTeamName