import React from 'react'
import style from "../style/venuesStyle.module.css"
import download from "../../../images/venues/download.svg"
const VenueHeader = () => {
  return (
    <div className={style.venueHeader}>
        <span> Venues </span>

        <div>
            <button className={style.upload}> <img src={download} alt="download" /> Upload Venues via CSV </button>
        </div>
    </div>

  )
}

export default VenueHeader