import React from 'react'
import styles from "./style.module.css";
import racle from "../images/admin/racle.svg";
import pen from "../images/admin/pen.svg";
const AccountImage = ({data}) => {
  return (
    <div className={styles.item}>
            <div className={styles.team}>
              <input type="checkbox" className={styles.check} />
              <div className={styles.round} style={{backgroundColor: data.backgroundColor}}></div>
              <div>
                <span className={styles.steamName}>{data.teamName}</span>
                <p className={styles.desctionTeamName}>{data.description}</p>
              </div>
            </div>
            <div className={styles.accountBox}>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                }}
              ></div>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                  position: "relative",
                  left: "-10px",
                }}
              ></div>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                  position: "relative",
                  left: "-20px",
                }}
              ></div>
                
            </div>
            <div style={data.style}>  {data.venues}  </div>
            <div className={styles.accountBox}>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                }}
              ></div>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                  position: "relative",
                  left: "-10px",
                }}
              ></div>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                  position: "relative",
                  left: "-20px",
                }}
              ></div>
              <div
                className={styles.accountImage}
                style={{
                  backgroundImage: `url("https://picsum.photos/id/1/200/300")`,
                  position: "relative",
                  left: "-30px",
                }}
              >
                </div>
                <div className={styles.end}>{data.length}</div>
            </div>
            <div className={styles.travel}>
              <div>
                <p>{data.travel}</p>
                <p className={styles.desctionTeamName}>{data.travel2}</p>
              </div>
              <div>
                <button>
                  <img src={racle} alt="" />
                </button>
                <button>
                  <img src={pen} alt="" />
                </button>
              </div>
            </div>
          </div>
  )
}

export default AccountImage