export const createSeasonStyle = {
  position: "absolute",
  background: " #FFFFFF",
  width: "713px",
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  left: "500px",
  top: "200px",
  height: "430px",
  boxShadow:
    " 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
};
