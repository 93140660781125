import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CreateNewPassword } from "../../../features/slice/loginSlice/loginApi/loginApi";
import style from "../style/createPas.module.css";
import { useSearchParams,useNavigate } from "react-router-dom";
const CreateNewPassForm = () => {
  const [queryParameters] = useSearchParams()
  const token =queryParameters.get('token')
  const email = queryParameters.get('email')
  const [pass, setPass] = useState({
    password: "",
    c_password: "",
    token:token,
    email:email
  });

 
  const handleChange = (e) => {
    setPass({ ...pass, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate()
 
  const handleResetPass = () => {
    if (pass.password === pass.c_password) {
      dispatch(CreateNewPassword({ pass })).unwrap().then((res)=>{
        if(res){
          navigate("/password/updated")
        }
      })
    }
  };
  return (
    <div className={style.createForm}>
      <div className={style.createInput}>
        <input
          type="password"
          name="password"
          placeholder="New password"
          value={pass.password}
          onChange={handleChange}
        />
        <input
          type="password"
          name="c_password"
          placeholder="Confirm new password"
          value={pass.confirm_password}
          onChange={handleChange}
        />
      </div>
      <div className={style.resetButton}>
        <button onClick={handleResetPass}> Restet password</button>
      </div>
    </div>
  );
};

export default CreateNewPassForm;
