export const mock_data = [
    {
      teamName: "Team Name",
      description: "Description",
      venues: "Home",
      travel: "Max Distance from Home Venue +2- 17/02/23 17:52",
      travel2: "Max Distance from Last game +4-",
      style:{
        backgroundColor:"#ECFDF3",
        color:"#027A48",
        width: "50px",
        height: "22px"
      },
      length:"+ 5",
      backgroundColor: "purple"
    },
    {
      teamName: "Team Name",
      description: "Description",
      venues: "Eligible",
      style:{
        background: "#F2F4F7",
        borderRadius: "16px",
        color:"#344054",
        width: "66px",
        height: "22px"

      },
      length:"+8",
      travel: "Max Distance from Home Venue +2- 17/02/23 17:52",
      travel2: "Max Distance from Last game +4-",
    },
    {
      teamName: "Team Name",
      description: "Description",
      venues: "Customer",
      length:"+2",
      style:{
        backgroundColor:"#ECFDF3",
        color:"#027A48",
        width: "50px",
        height: "22px"
      },
      travel: "Max Distance from Home Venue +2- 17/02/23 17:52",
      travel2: "Max Distance from Last game +4-",
    },
    {
      teamName: "Team Name",
      description: "Description",
      venues: "Customer",
      travel: "Max Distance from Home Venue +2- 17/02/23 17:52",
      style:{
        backgroundColor:"#ECFDF3",
        color:"#027A48",
        width: "50px",
        height: "22px"
      },
      travel2: "Max Distance from Last game +4-",
    },
    {
      teamName: "Team Name",
      description: "Description",
      venues: "Churned",
      travel: "Max Distance from Home Venue +2- 17/02/23 17:52",
      length:"+1",
      style:{
        background: "#F2F4F7",
        borderRadius: "16px",
        color:"#344054",
        width: "70px",
        height: "22px"
      },
      travel2: "Max Distance from Last game +4-",
    },
    {
      teamName: "Team Name",
      description: "Description",
      venues: "Customer",
      style:{
        backgroundColor:"#ECFDF3",
        color:"#027A48",
        width: "50px",
        height: "22px"
      },
      travel: "Max Distance from Home Venue +2- 17/02/23 17:52",
      length:"+6",
      travel2: "Max Distance from Last game +4-",
    },
  ];