import React from "react";
import styles from "./style.module.css";
import bottom from "../images/admin/bottom.svg";

import { mock_data } from "./tableData";
import AccountImage from "./AccountImage";



const Table = () => {
  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <div className={styles.team}>
          <input type="checkbox" className={styles.check} variant="minus" />
          <span>
            Team Name <img src={bottom} alt="" />
          </span>
        </div>
        <span>Coaches</span>
        <span>Venues</span>
        <span>Players</span>
        <span className={styles.travel}>Travel Constraints</span>
      </div>
      {mock_data.map((e, index) => {
        return (
          <AccountImage key={index} data={e} />
        );
      })}
    </div>
  );
};

export default Table;
