import React from 'react'
import style from "../style/update.module.css"
import done from "../../images/done.png"
const UpdateIcon = () => {
  return (
    <div className={style.updateIcon}>
            <img src={done} alt="done" />
    </div>
  )
}

export default UpdateIcon