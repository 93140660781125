import React from 'react'
import style from "../style/edit.module.css"
const EditPagesHeader = ({onClick}) => {
  return (
    <div className={style.editPageHeader}>
        <span> Edit Venue</span>
        <button onClick={onClick}> Save Venue</button>
    </div>
  )
}

export default EditPagesHeader