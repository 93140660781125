import React from 'react'
import AdminScheduls from './AdminScheduls'

import AdminTotalDivisions from './AdminTotalDivisions'
import AdminTotalTeams from './AdminTotalTeams'
const AdminDivisions = () => {
  return (
    <div className='admin-divisions'>
       <AdminTotalDivisions />
        <AdminTotalTeams />
        <AdminScheduls />
    </div>
  )
}

export default AdminDivisions