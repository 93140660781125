import React from 'react'
import style from "./style/addStyle.module.css"
const Apply = () => {
  return (
    <div className={style.apply}>
        <div className={style.applyText}>
          <input type="checkbox" /> <label> Apply these constraints to all Teams in Division</label>
        </div>

    </div>
  )
}

export default Apply