import React from 'react'
import point from "../../images/admin/3point.png"
import top from "../../images/admin/top.png"
import line from "../../images/admin/line.png"
const AdminScheduls = () => {
  return (
    <div>
         <div className='total-divisions'>
    <div className='text-total-divisions'>
        <span> Scheduls</span>
        <img src={point} alt="point" width={1.67} height={13.3} />
          </div>
        
         <div className='divisions-footer'>
            <div>
              <span className='text-number'>316</span>
            <div className='text-box'><img src={top} alt="top" />
            <span className='pracent'>40% </span>
            <span>vs last month</span></div>
            </div>
            <img src={line} alt="line" className='text-line' />
         </div>

     </div>
    </div>
  )
}

export default AdminScheduls