import React from 'react'
import style from "./style/gameModalStyle.module.css"
const GameTable = () => {
  return (
    <>
    <div className={style.gameModalTeamNumber}>
        <div className={style.teamNameStyle}> <span> Team1 </span> </div>
        <div className={style.teamNameStyle}> <span> Team2 </span> </div>
        <div className={style.teamNameStyle}> <span> Team3 </span> </div>
        <div className={style.teamNameStyle}> <span> Team4 </span> </div>
        <div className={style.teamNameStyle}> <span> Team5 </span> </div>
        <div className={style.teamNameStyle}> <span> Team6 </span> </div>
        <div className={style.teamNameStyle}> <span> Team7 </span> </div>
        <div className={style.teamNameStyle}> <span> Team8 </span> </div>

      </div>
      <div className={style.gameTableColumn}>

      <div className={style.score}> <span> Team 1 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>
      <div className={style.score}> <span> 0-3 </span></div>

      </div>

      <div className={style.gameTableColumn}>

<div className={style.score}> <span> Team 2 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>

</div>

<div className={style.gameTableColumn}>

<div className={style.score}> <span> Team 3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>

</div>

<div className={style.gameTableColumn}>

<div className={style.score}> <span> Team 4 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>
<div className={style.score}> <span> 0-3 </span></div>

</div>
    </>
  )
}

export default GameTable