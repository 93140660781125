import React from "react";
import style from "./style/adminModalStyle.module.css";
import greenplus from "../../images/admin/greenplus.svg";
const AdminModalHeader = () => {
  return (
    <div className={style.adminModalHeader}>
      <div className={style.headerIcon}>
                <img src={greenplus} alt="greenplus" />
      </div>

      <div className={style.headerTitle}>
        <span> Create </span>
        <p>

          Lorem ipsum dolor sit amet consectetur. Auctor orci lorem malesuada
          ultricies enim lobortis quam viverra.
        </p>
      </div>
    </div>
  );
};

export default AdminModalHeader;
