import React from 'react'
import CompanyMenu from '../companyMenu'
import  "./style/mainStyle.css"
import { Outlet } from 'react-router-dom'
const Main = () => {
  return (
    <div className='main'>
  
        <CompanyMenu />
        <Outlet />
    </div>
  )
}

export default Main