import React from 'react'
import "../style/style.css"
const GameListScrool = () => {
  return (
    <div class="scrollbar" id="style-1">
    <div class="force-overflow"></div>
  </div>
  )
}

export default GameListScrool