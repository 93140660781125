import React from 'react'
import style from "./style/update.module.css"
import UpdateIcon from './updatePassPage/UpdateIcon'
import UpdateMessage from './updatePassPage/UpdateMessage'
const PasswordUpdated = () => {
  return (
    <div className={style.passwordUpdate}>
        <UpdateIcon />
        <UpdateMessage />
    </div>
  )
}

export default PasswordUpdated