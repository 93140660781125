import React from 'react'
import CreateNewPassForm from './createNewPasPages/CreateNewPassForm'
import CreateNewPassText from './createNewPasPages/CreateNewPassText'
import style from "./style/createPas.module.css"
const CreateNewPassword = () => {
  return (
   <div className={style.createNewPassword}>
        <CreateNewPassText />
        <CreateNewPassForm />
   </div>
  )
}

export default CreateNewPassword