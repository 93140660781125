import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createSeasonStyle } from './style/createSeasonStyle';
import CreateSeasonTitle from './seasonPage/CreateSeasonTitle';
import { useNavigate } from 'react-router-dom';

const CreateSeason = () => {
  const navigate = useNavigate()
  const nextPage = () => {
    navigate("/select/division")
  }
  const cancelPage = () => {
    navigate("/admin")
  }
  return (
    <Modal
    open={true}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{...createSeasonStyle }}>
      <CreateSeasonTitle nextPage={nextPage} cancelPage={cancelPage}/>
    </Box>
  
  </Modal>
  )
}

export default CreateSeason