import React from 'react'
import style from "./style/addCoach.style.module.css"
import { useSelector } from "react-redux";
const AddCoachModalMidle = () => {
const {team_coach,eligible_coach} = useSelector(state=> state.division)

  return (
    <div className={style.addCoachModalMidle}>

        <div className={style.eligible}>
            <label> Eligible Coaches:{eligible_coach.length} </label>
            <select className={style.coachSelect}>
               {
                    eligible_coach.map((item)=>(
                       
                                        <option key={item.id} >   {item.first_name} {item.last_name}</option>
                            
                    ))
               } 
         

            </select>
          
           

        </div>

        <div className={style.team}>
            <label> Team Coaches:{team_coach.length} </label>

            <select className={style.coachSelect}>
               {
                    team_coach.map((item)=>(
                       
                                        <option key={item.id} >   {item.first_name} {item.last_name}</option>
                            
                    ))
               } 
         

            </select>
          

        </div>

    </div>
  )
}

export default AddCoachModalMidle