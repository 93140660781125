import React from 'react'
import logoCompany from "../../images/companymenu/logocompany.png"
import exit from "../../images/companymenu/exit.svg"
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {logOut} from "../../features/slice/loginSlice/loginSlice";


const CompanyUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const email = localStorage.getItem("email") || sessionStorage.getItem("email")
  const firstName = localStorage.getItem("firstname") || sessionStorage.getItem("firstname")
  const handleExit = () => {
    dispatch(logOut())
    navigate("/login") 
  }
  return (
    <div className='companu-user'>
        <div> <img src={logoCompany} alt="logoCompany"/> </div>
        <div className='info-users'>
            <span> {email}</span>
            <p className='user-emails'> {firstName} </p>
              </div>
              <div className='exit'> <img src={exit} alt="exit" onClick={handleExit}/> </div>
    </div>
  )
}

export default CompanyUser