import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link} from 'react-router-dom'
import { ForgotPassword } from '../../../features/slice/loginSlice/loginApi/loginApi'
import style from "../style/forgotStyle.module.css"
const ForgotPasswordForm = () => {
  const [email,setEmail] = useState("")

const dispatch = useDispatch()
  const handleSendEmail = () => {
   
      dispatch(ForgotPassword({email:email}))
     
  }
  return (
    <div className={style.forgotPasswordForm}>
            <input type = "text" placeholder='Enter your email' value={email} onChange={(e=>setEmail(e.target.value))}  />

            <div className={style.send}>
            <button className={style.sendButton} onClick={handleSendEmail}> Send instruction </button>
            <button className={style.backButton}> <Link to="/login">Back to log in </Link> </button>

            
            </div>
            <div>
            <span className={style.dont}>Dont have account?  </span> <Link className={style.signLink} to="/registration"> Sign up </Link>
            </div>
          

    </div>
  )
}

export default ForgotPasswordForm