import point from "../../../../images/schedules/point.svg"
import bottom from "../../../../images/schedules/bottom.png"
import top from "../../../../images/schedules/top.png"
export const schedulesData = [
    {
            schedulesName:"Schedule Name",
            icon:point,
            divisionName:"Division name",
            results:"Results",
            href:"/aboutschedules",
            scheduled:"1 Scheduled 0 Unscheduled",
            top:top,
            style:{
                color:"#027A48",

            },
            percent:"20% Success",
            created:"Created",
            data:"22/02/2023"


},
{
    schedulesName:"Schedule Name",
    icon:point,
    divisionName:"Division name",
    results:"Results",
    scheduled:"1 Scheduled 0 Unscheduled",
    top:bottom,
    style:{
        color:"#B42318",
        
    },
    percent:"100%",
    created:"Created",
    data:"22/02/2023"


},
{
    schedulesName:"Schedule Name",
    icon:point,
    divisionName:"Division name",
    results:"Results",
    scheduled:"1 Scheduled 0 Unscheduled",
    top:bottom,
    style:{
        color:"#B42318",
        
    },
    percent:"100%",
    created:"Created",
    data:"22/02/2023"


}

]