import style from "./createNewStyle/creeateNewStyle.module.css"
import greenplus from "../../../images/division/greenplus.svg"
const CreateNewTeamHeader = () => {
    return(
        <div className={style.createNewTeamHeader}>
            <div className={style.createIcon}> <img src={greenplus} alt="create" /> </div>
             <span> Create New  team</span>
        </div>
    )
}
export default  CreateNewTeamHeader