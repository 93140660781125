import React from 'react'
import style from "../schedulesstyle/schedules.module.css"
import schedules from "../../../images/schedules/download.svg"
import plus from "../../../images/schedules/plus.svg"
const SchedulesUploadCv = () => {
  return (
    <div className={style.schedulesUpload}>
        <div className={style.downloadFIle}>
        <span class="input-group-text" for="inputGroupFile"> <img src={schedules}  alt="schedules"/> Upload Schedule via CSV</span>
        <input type="file" />
        </div>
        <div className={style.createSchedules}>
      <button> <img src={plus} alt="plus" />  <span> Create Schedule </span> </button>
             </div>        
    </div>
  )
}

export default SchedulesUploadCv