import React, { useEffect } from "react";
import style from "./createNewStyle/creeateNewStyle.module.css";
import CreateNewTeamFooter from "./CreateNewTeamFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateTeam,
  getDivisionTeam,
} from "../../../../component/features/slice/divisionSlice/divisionApi/divisionApi";
import { useNavigate } from "react-router-dom";

const CreateNewTeamMiddle = ({ createTeam, onChange }) => {
  const dispatch = useDispatch();
  const { divisionTeam } = useSelector((state) => state.division);

  const handleCreate = () => {
    dispatch(CreateTeam({ createTeam }))
  };
  const navigate = useNavigate();
  const handleCancle = () => {
    navigate("/divisions");
  };

  useEffect(() => {
    dispatch(getDivisionTeam());
  }, []);
  return (
    <div className={style.createNewTeamMiddle}>
      <div className={style.createInputs}>
        <div className={style.teamName}>
          <span> Team name </span>
          <input
            type="text"
            data-id="name"
            placeholder="Team Name"
            value={createTeam.name}
            onChange={onChange}
          />
        </div>
        <div className={style.teamName}>
          <span> Abbrevuatuion </span>
          <input
            type="text"
            data-id="abbreviation"
            placeholder="Abbreviation name"
            value={createTeam.abbreviation}
            onChange={onChange}
          />
        </div>

        <div className={style.teamName}>
          <span> Custom Code </span>
          <input
            type="text"
            data-id="customCode"
            placeholder="Custom name"
            value={createTeam.customCode}
            onChange={onChange}
          />
        </div>

        <div className={style.teamName}>
          <span> Division name </span>
          <input
            type="text"
            data-id="division_name"
            placeholder="Division Name"
            value={createTeam.division_name}
            onChange={onChange}
          />
        </div>
        <div className={style.teamName}>
          <span> Division id </span>
          <select data-id="division_id" onChange={onChange} value={createTeam.division_id}>
            {divisionTeam.map((elem) => (
              <option key={elem.id} value={elem.id}>  {elem.name} </option>
            ))}
          </select>
        </div>
      </div>

      <div className={style.createFooter}>
        <CreateNewTeamFooter
          handleCreate={handleCreate}
          handleCancle={handleCancle}
        />
      </div>
    </div>
  );
};

export default CreateNewTeamMiddle;
