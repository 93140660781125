import React from 'react'
import filters from "../../images/admin/filters.png"
const AdminMoreFilter = () => {
  return (
    <button className='button-more-filters'>
        <img src={filters} alt="filters" />
       <span> More filters</span> 
    </button>
  )
}

export default AdminMoreFilter