import React from "react";

import style from "../../style/aboutSchedules.module.css";
import { retry } from ".././aboutSchedulesData/retry";

const ScheduleSettings = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedModal, setSelectedModal] = React.useState(null);

  const handleModalOpen = ({ index }) => {
   
    
    setSelectedModal(index);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedModal(null);
  };

  return (
    <div className={style.scheduleSettings}>
      {retry.map(({ name, around, Modal, style }, index) => (
        <div key={index} style={style}>
          <img src={around} alt={name} />
          <span onClick={() => handleModalOpen({ index })}> {name} </span>
          { selectedModal === index && (
            <Modal
               open={showModal}
              showModal={setShowModal}
              handleModalClose={handleModalClose}
              
            />
          )}
        </div>
      ))}
      <div> </div>
    </div>
  );
};

export default ScheduleSettings;
