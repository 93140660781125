import React from "react";
import style from "./style/divisionModalStyle.module.css";
import delet from "../../../images/division/delete.svg";
import download from "../../../images/division/download.svg";

const DivisionModalEditFooter = ({handleClose, handleSubmit}) => {
  return (
    <div className={style.divisionModalEditFooter}>
      <div className={style.deleteDivision}>
        <button className={style.deleteDivisionButton}>
         
          <img src={delet} alt="delet" /> <span>Delete Division</span>
        </button>
        <button className={style.UploadDivisionButton}>
        
          <img src={download} alt="download" />
          <span>Upload Divisions via CSV</span>
        </button>
      </div>
      <div className={style.chooseButton}>
        <button className={style.sizeCancle} onClick={handleClose}> Cancel </button>
        <button className={style.sizeSave} onClick={handleSubmit}> Save </button>
      </div>
    </div>
  );
};

export default DivisionModalEditFooter;
