import React from "react";
import { Link, useLocation } from "react-router-dom";
import { dataCompany } from "./data/company.js";

const CompanyMenuNavBar = () => {
  
  const { pathname } = useLocation();

  return (
    <div className="company-menu-nav-bar">
      {dataCompany.map((item) => (
        <ul key={item.id} className={`container-nav-bar${pathname === item.href ? " active" : ""}`}>
          <li className="li-size-bar">
            <Link to={item.href}>
              <span className="side-bar-icon">
                <img src={item.image} alt="home" />
              </span>
              <span className="home-text-style"> {item.title}</span>
            </Link>
          </li>
        </ul>
      ))}
    </div>
  );
};

export default CompanyMenuNavBar;
