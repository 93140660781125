export const daysWeek =[
    {
        day:"Sunday"
    },

    {
        day:"Monday"
    },

    {
        day:"Tuesday"
    },

    {
        day:"Wednesday"
    },

    {
        day:"Thursday"
    },

    {
        day:"Friday"
    },
    {
        day:"Saturday"
    },
]


export const checkboxDay = [
    {
        day:"Sun"
    },

    {
        day:"Mon"
    },

    {
        day:"Tue"
    },

    {
        day:"Wed"
    },

    {
        day:"Thu"
    },

    {
        day:"Fri"
    },
    {
        day:"Sat"
    },
]