import React from "react";
import style from "../style/createPas.module.css";
const CreateNewPassText = () => {
  return (
    <div className={style.createNewPassText}>
      <span>Create Password</span>
      <p> Please enter your new password </p>
    </div>
  );
};

export default CreateNewPassText;
