import React from 'react'
import style from "../style/details.module.css"
const DetailsPersonalInfo = () => {
  return (
    <div className={style.detailsPerson}>
        <div className={style.personalText}>
            <span> Personal info</span>
            <p>Update your photo and personal details here. </p>

        </div>

        <div className={style.detailsButton}>
            <button className={style.cancel}> Cancel </button>
            <button className={style.save}> Save </button>

        </div>

    </div>
  )
}

export default DetailsPersonalInfo