import React from "react";
import style from "./style/retryModal.module.css";

const RetryModalMiddle = () => {
  return (
    <div className={style.retryModalMiddle}>
      <div className={style.checkBoxText}>
        <div className={style.avoid}>
          <input type="checkbox" />
          <span>Avoid double-booking Coaches</span>
        </div>

        <div className={style.balacne}>
          <input type="checkbox" />
          <span>Balance days, times, venues</span>
        </div>
      </div>

      <div className={style.enforce}>
        <div className={style.avoid}>
          <input type="checkbox" />
          <span>Enforce home team venues </span>
        </div>

        <div className={style.balacne}>
          <input type="checkbox" />
          <span>Use Venue Priority/Balancing</span>
        </div>
      </div>

      <div className={style.enforce}>
        <div className={style.avoid}>
          <input type="checkbox" />
          <span>Balance days, times </span>
        </div>

        <div className={style.balacne}>
          <input type="checkbox" />
          <span>Use Weekend Method</span>
        </div>
      </div>
    </div>
  );
};

export default RetryModalMiddle;
