import { createSlice } from "@reduxjs/toolkit";
import { getVenuDate, GetVenueUserId } from "./venuesApi";

const venueSlice = createSlice({
  name: "venue",
  initialState: {
    allVenues: [],
    venue: {},
  },
  reducers: {
    editVenue: (state, action) => {
      const id = action.payload;
      state.venue = state.allVenues.find((item) => item.id === id);
    },
    changeVenue:(state,action)=>{
      
      state.venue={...state.venue,...action.payload}

    }
  },
  extraReducers: (bulider) => {
    bulider.addCase(getVenuDate.fulfilled, (state, action) => {
      state.allVenues = action.payload;
    })
    .addCase(GetVenueUserId.fulfilled, (state, action) => {
      state.venue = action.payload
    })
  },
});

export const { editVenue, changeVenue} = venueSlice.actions;
export const venueSliceRedcer = venueSlice.reducer;
