import React from 'react'
import style from "../modal/style/addCoach.style.module.css"
const CoachModalFooter = ({handleClose,onClick}) => {
  return (
    <div className={style.addCoachModalFooter}>
    <button className={style.addCoachButtonSave} onClick={onClick}> Save</button>
    <button className={style.addCoachButtonCancel} onClick={handleClose}> Cancel </button>
</div>
  )
}

export default CoachModalFooter