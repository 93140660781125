import React from 'react'
import style from "./style/gameModalStyle.module.css"
const GameModalPagesHeader = () => {
  return (
    <div className={style.gameModalPagesHeader}>
        <div className={style.teamSize}> <span className={style.teamBalanceText}>Team Balance </span></div>
        <div className={style.printBalance}>
            <button className={style.printBalancButton}> Print Balance </button>
            <button className={style.printDownload}> Download CSV </button>
        </div>
    </div>
  )
}

export default GameModalPagesHeader