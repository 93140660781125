import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { selectDivisionStyle } from './style/selectDivisionStyle';
import SelectDivisionTitle from './selectDivisionPage/SelectDivisionTitle';
import SelectDivisionForm from './selectDivisionPage/SelectDivisionForm';
import { useNavigate } from 'react-router-dom';
const SelectDivision = () => {
  const navigate = useNavigate()
  const nextPage = () => {
    navigate("/seasos/team/name")
  }
  const cancelPage = () => {
    navigate("/admin")
  }
  return ( 
    <Modal
    open={true}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{...selectDivisionStyle }}>
       <SelectDivisionTitle />
       <SelectDivisionForm  nextPage={nextPage}  cancelPage={cancelPage}/>
    </Box>
  
  </Modal>
  )
}

export default SelectDivision