import React from 'react'
import style from "./style/creatCoach.module.css"
const CoachModalForm = ({coach,onChange}) => {
  return (
    <div className={style.coachModalForm}>

        <div className={style.phone}>
        <span> Home phone </span>
        <input type="text" name='home_phone' value={coach.home_phone} placeholder='Home phone' onChange={onChange}/>
        </div>

        <div className={style.phone}>
        <span> Work phone </span>
        <input type="text" name='work_phone' value={coach.work_phone} placeholder='Work phone' onChange={onChange}/>
        </div>

        <div className={style.phone}>
        <span> Primary email </span>
        <input type="text" name='primary_email' value={coach.primary_email} placeholder='primary email'onChange={onChange} />
        </div>

        <div className={style.phone}>
        <span> Secondary email </span>
        <input type="text" name="secondary_email" value={coach.secondary_email}  placeholder='secondary phone'onChange={onChange} />
        </div>

    </div>
  )
}

export default CoachModalForm