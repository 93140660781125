import React from 'react'
import AdminFilterNameButton from './AdminFilterNameButton'
import AdminMoreFilter from './AdminMoreFilter'
import AdminSerachInput from './AdminSerachInput'

const AdminFilters = () => {
  return (
    <div className='admin-filters'>
      <div className='filters-button'>
      <AdminFilterNameButton />
      <AdminFilterNameButton />
      <AdminMoreFilter />
      </div>
      
      <AdminSerachInput />
    </div>
  )
}

export default AdminFilters