import React from 'react'
import style from "./plus.module.css"
import plus from "../../../images/season/plus.svg"
const PlusPage = () => {
  return (
    <div className={style.plus}>
        <img src={plus} alt="plus" />
    </div>
  )
}

export default PlusPage