import React from 'react'
import style from "./style/view.module.css"
import ViewFooter from './ViewFooter'
import ViewPageHeader from './ViewPageHeader'
const ViewPage = () => {
  return (
    <div className={style.viewPage}>
            <ViewPageHeader />
            <ViewFooter />

    </div>
  )
}

export default ViewPage