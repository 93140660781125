export const createButtonStyle = {
  position: "absolute",
  background: " #FFFFFF",
  left: "600px",
  top: "100px",
  boxShadow:" 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  width: "400px",
  height: "630px",
  paddingLeft: "24px",
  paddingTop:"24px"
};
