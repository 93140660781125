import React from 'react'
import style from "../style/calendarStyle.module.css";
const CalendarHeaderLastSelect = () => {
  return (
    <div className={style.calendarChoose}>
    <span>Choose Divisions </span>
      <select>
        <option >Divisions name</option>
      </select>
      <div className={style.calendarSurfaces}>
  <span>Teams </span>
      <select>
        <option >Team name</option>
      </select>
  </div>

    </div>
  )
}

export default CalendarHeaderLastSelect