import React from 'react'
import style from "../style/gameListStyle.module.css";
const GamePageNextPrev = () => {
  return (
    <div className={style.gamePageNextPrev}>
        <div className={style.gamePageination}>
            <button className={style.gameButtonPrevius}> Previous </button>

            <button className={style.gameButtonNext}> Next </button>
        </div>
        <div className={style.gamePageLength}>
            <span> Page 1 of 10  </span>
        </div>
    </div>
  )
}

export default GamePageNextPrev