export const createNewStyle = {
    position: "absolute",
    background: " #FFFFFF",
    left: "365px",
    top: "86px",
    boxShadow:
        " 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    width: "713px",
    height: "595px",
    paddingTop: "24px",
    paddingLeft: "24px",
};
