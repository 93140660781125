import React from 'react'
import style from "../style/details.module.css";
import { checkboxDay, daysWeek } from './detailsData/daysWeek';
const DetailsCalendar = () => {
  return (
    <div className={style.detailsCalendar}>
         <span>Calendar information</span>
         <div className={style.firstDay}>
            <span className={style.firstText}> First day of week  </span>
            {
                <select>
                    {daysWeek.map(({day},index)=>(
                        <option key={index}>
                            {day}
                        </option>
                    ))}
                </select>
            }
            <div className={style.checkbox}>

                {
                    checkboxDay.map(({day},index)=>(
                        <div key={index} className={style.checkboxDay}>
                            <input type="checkbox" /> <span> {day} </span>
                            </div>
                    ))
                }

            </div>

         </div>
    </div>
  )
}

export default DetailsCalendar