import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import style from "../style/retryModal.module.css"
import { modalStyle } from '../style/style';
import RetryModalHeader from './RetryModalHeader';
import RetryModalMiddle from '../RetryModalMiddle';
import RetryModalFooter from './RetryModalFooter';
const RetryModal = ({showModal,handleModalClose}) => {
  return (
    <div className={style.retryModal} >
        <Modal
        open={showModal}
       
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...modalStyle }}>

            <RetryModalHeader />
             <RetryModalMiddle />
             <RetryModalFooter  handleModalClose={handleModalClose}/>
        </Box>
      </Modal>
       
    </div>
  )
}

export default RetryModal