export const gameModalstyle = {
  position: "absolute",
  background: " #FFFFFF",
  left:"100px",
  top:"50px",
  boxShadow:" 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",    
  borderRadius: "12px", 
  width: "76%",
  overflowY: "scroll",
  paddingLeft:"52px",

  
  
};
