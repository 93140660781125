import React from "react";
import style from "../style/retryModal.module.css";
const RetryModalFooter = ({handleModalClose}) => {
  return (
    <div className={style.retryModalFooter}>
      <div className={style.gamesDays}>
        <div>
          <span> Max Games/Day </span>
          <input type="number" className={style.maxGames} />
        </div>

        <div className={style.nights}>
          <span> Nights Between Games </span>
          <input type="number" className={style.maxGames} />
        </div>

        <div className={style.gameCancle}>
        <button onClick={handleModalClose}>Cancle</button>
      </div>
      </div>

       <div className={style.gamesDays}>
        <div>
          <span> Max Games/week </span>
          <input type="number" className={style.maxGames} />
        </div>

        <div className={style.nights}>
          <span> Minutes Between Games </span>
          <input type="number" className={style.maxGames} />
        </div>
        <div className={style.submitRetry}>
        <button>Submit Retry</button>
      </div>

      </div> 
     
    </div>
  );
};

export default RetryModalFooter;
