import React from 'react'
import style from "./style/addCoach.style.module.css"
import plus from "../../../../../images/division/plus.svg"
import CreateCoachModal from '../createCoachModal'
const CreateNewCoach = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={style.createMewCoach}>

    <button className={style.createCoach} onClick={handleOpen}> <img src={plus} alt="plus" /> Create new coach </button>
    <CreateCoachModal  open={open}  handleClose={handleClose}/>

    </div>
  )
}

export default CreateNewCoach