import React from 'react'
import style from "../../modal/style/addCoach.style.module.css"
const AddSaveCancle = ({handleClose}) => {
  return (
    <div className={style.addCoachModalFooter}>
    <button className={style.addCoachButtonSave}> Save</button>
    <button className={style.addCoachButtonCancel} onClick={handleClose}> Cancel </button>
</div>
  )
}

export default AddSaveCancle