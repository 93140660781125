import React from 'react'
import style from "./style/creatCoach.module.css"
const CoachModalCheckbox = ({coach,onChange}) => {

  return (
    <div className={style.coachModalCheckbox}>
      <div className={style.coachCheck}>
        <select value={coach.person_role} name='person_role' onChange={onChange}>
          <option> Enter </option>
          <option> Coach </option>
          <option> Player </option>
          <option> Player </option>
          <option> Staff </option>
        </select>
  
      </div>
     
    </div>
  )
}

export default CoachModalCheckbox