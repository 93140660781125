import React from 'react'
import style from "../../style/aboutSchedules.module.css"
const AboutSchedulesBottom = () => {
  return (
    <div className={style.aboutSchedulesBottom}>
        <div className={style.numberBottom}> <span> 1 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
        <div className={style.numberBottom}> <span> 4 </span></div>
    </div>
  )
}

export default AboutSchedulesBottom