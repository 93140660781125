import React from 'react'
import style from "../style/createVenuesStyle.module.css"
const CreateVenuesHeader = ({handleCreateVenue,handleClose}) => {

  return (
    <div className={style.createVenuesHeader}>
        <h1> Create Venue  </h1>
        <div className={style.buttonsStyle}>
            <button className={style.create} onClick={handleCreateVenue}> Create </button>
            <button className={style.cancel} onClick={handleClose}> Cancel</button>
        </div>
    </div>
  )
}

export default CreateVenuesHeader