import React from 'react'
import AdminButtonCreate from './AdminButtonCreate'

const AdminHeader = () => {

  const firstName = localStorage.getItem("firstname") || sessionStorage.getItem("firstname")
  return (
    <div className='admin-header'>
        <div className='welcome-text'>
             <span className='welocome-text-size'> Welcome back,{firstName} </span>  
             <span className='lorem-text'>Lorem ipsum dolor sit amet consectetur. Ut quis cursus cum a tellus malesuada quis.</span>
             </div>
             <div className='size-create-button'>
             <AdminButtonCreate />
             </div>
             
    </div>
  )
}

export default AdminHeader