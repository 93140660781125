import React from 'react'
import done from "../../../../../../images/schedules/done.svg"
import style from "./style/editModal.module.css"
const EditModalHeader = () => {
  return (
    <div  className={style.editModalHeader}>
        <div className={style.editModalIcon}>
        <img src={done} alt="done" />
        </div>
       <div className={style.editNameText}> Edit Schedule Name </div>
    </div>
  )
}

export default EditModalHeader