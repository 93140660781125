import React from 'react'
import vectorBottom from "../../../images/gamelist/vectorbottom.svg";
import vectorTop from "../../../images/gamelist/vectortop.svg";
import style from "./style/gameModalStyle.module.css";
const GameRowTable = () => {
  return (
   <>
   <div className={style.rowTable}>
        <div className={style.team}>
       
          <span>Team </span>
          <img src={vectorTop} alt="vectortop" className={style.vectorTop} />
          <img
            src={vectorBottom}
            alt="vectorBottom"
            className={style.vectorBottom}
          />
        </div>

        <div className={style.team}>
       
       <span>Goodison Park </span>
       <img src={vectorTop} alt="vectortop" className={style.vectorTop} />
       <img
         src={vectorBottom}
         alt="vectorBottom"
         className={style.vectorBottom}
       />
     </div>

      
      </div>
   </>
  )
}

export default GameRowTable