import React, { useEffect, useState } from "react";
import style from "./style/edit.module.css"
import EditPagesHeader from "./editpages/EditPagesHeader";
import EditPagesForm from "./editpages/EditPagesForm";
import { useDispatch, useSelector } from "react-redux";
import { changeVenue } from "../../../../component/features/slice/venuesSlice/venuesSlice";
import EditPagesMiddle from "./editpages/EditPagesMiddle";
import EditPagesFooter from "./editpages/EditPagesFooter";
import { EditVenue, GetVenueUserId } from "../../../../component/features/slice/venuesSlice/venuesApi";
import { useLocation, useNavigate } from "react-router-dom";
const EditPage = () => {
  const { venue } = useSelector(state=>state.venue)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { hash } = useLocation()
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
  
    if(!!venue) {
      dispatch(GetVenueUserId(hash.replace('#', '')))
      .unwrap()
      .then((res) => res ?? navigate('/venues'))
    }
  }, [])

  const handleChange = (e)=>{
    
  
    dispatch(changeVenue({...venue,[e.target.name]:e.target.value}))

  }
  const handleEditVenue = () => {
    const  as_home_team = isChecked ? 1 : 0
    const obj = {...venue,as_home_team}
   
    dispatch(EditVenue(obj)).unwrap().then((res)=>{
      if(res){
        navigate("/venues")
      }
    })
  }

  if(!venue) {
    return null;
  }

  return (
    <div className={style.editPage}>
    <EditPagesHeader  onClick = {handleEditVenue}/>
    <EditPagesForm venue={venue} handleChange={handleChange} />
    <EditPagesMiddle venue={venue} onChange={handleChange} />
    <EditPagesFooter venue={venue} isChecked={isChecked} setIsChecked={setIsChecked} onChange={handleChange}/>
     </div>
  );
};

export default EditPage;
