import React from 'react'
import style from "./style/adminModalStyle.module.css";
const AdminModalFooter = ({handleClose, handleSubmit}) => {
  return (
    <div className={style.adminModalFooter}>
        <button className={style.adminCancleButton} onClick={handleClose}> Cancle </button>
        <button className={style.adminCreateButton} onClick={handleSubmit}> Create </button>
    </div>
  )
}

export default AdminModalFooter