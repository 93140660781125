import around from "../../../../../images/schedules/around.svg";
import racle from "../../../../../images/schedules/racle.svg";
import DeleteModal from "../schedulesModal/deleteModal";
import EditName from "../schedulesModal/editNameModal";
import RetryModal from "../schedulesModal/retryModal";

export const retry = [
  {
    name: "Retry Schedule",
    around: around,
    Modal: RetryModal,

    style: {
      width: "164px",
      height: "40px",
      paddingLeft: "17.6px",
      borderRight: "2px solid #eaecf0",
    },
  },
  {
    name: "View Log",
    around: around,

    style: {
      width: "123px",
      height: "40px",
      paddingLeft: "17.6px",
      borderRight: "2px solid #eaecf0",
    },
  },
  {
    name: "Share Link",
    around: around,
    href: "/sharelink",
    style: {
      width: "131px",
      height: "40px",
      paddingLeft: "17.6px",
      borderRight: "2px solid #eaecf0",
    },
  },

  {
    name: "Embed Code",
    around: around,
    href: "/embedcode",
    style: {
      width: "147px",
      height: "40px",
      paddingLeft: "17.6px",
      borderRight: "2px solid #eaecf0",
    },
  },
  {
    name: "View Calendar",
    href: "/viewcalendar",
    around: around,
    style: {
      width: "159px",
      height: "40px",
      paddingLeft: "17.6px",
      borderRight: "2px solid #eaecf0",
    },
  },
  {
    name: "Edit name",
    around: around,
    Modal:EditName,
    href: "/editname",
    style: {
      width: "128px",
      height: "40px",
      paddingLeft: "17.6px",
      borderRight: "2px solid #eaecf0",
    },
  },
  {
    name: "Delete",
    Modal:DeleteModal,
    style: {
      background: "#667085",
      width: "108px",
      height: "44px",
      color: "#ffff",
      display: "flex",
      justifyCcontent: "center",
      paddingLeft: "17.6px",
    },
    around: racle,
  },
];
