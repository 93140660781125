import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Main from "../src/component/main/index";
import Calendar from "./assets/pages/calendar";
import Divisions from "./assets/pages/divisions";
import DivisionManage from "./assets/pages/divisions/divisionManage";
import DivisionModal from "./assets/pages/divisions/DivisionModal";
import GameList from "./assets/pages/gamelist";
import People from "./assets/pages/people";
import Schedules from "./assets/pages/schedules";
import AboutSchedules from "./assets/pages/schedules/aboutSchedules";
import Venues from "./assets/pages/venues";
import EditPage from "./assets/pages/venues/editPage";
import ViewPage from "./assets/pages/venues/viewpage";
import Admin from "./component/admin/index";
import ForgotPassword from "./component/siginSinUp/forgotPassword";

import Login from "./component/siginSinUp/login";
import Registration from "./component/siginSinUp/registration";

import "./global.css";
import CreateNewTeamModal from "./assets/pages/divisions/createNewTeamModal";
import { selectAuthIn } from "./component/features/slice/loginSlice/loginSlice";
import Settings from "./assets/pages/settings";
import MyDeatails from "./assets/pages/settings/myDetails";
import ManageLeagues from "./assets/pages/settings/manageLeagues";
import CreateNewPassword from "./component/siginSinUp/createNewPassword";
import PasswordUpdated from "./component/siginSinUp/passwordUpdated";
import Season from "./assets/pages/season/createLeague";
import CreateSeason from "./assets/pages/season/createSeason";
import SelectDivision from "./assets/pages/season/selectDivision";
import SeasonTeamName from "./assets/pages/season/seasonTeamName";
import CreateVenues from "./assets/pages/venues/createVenues";

function App() {
  const authIn = useSelector(selectAuthIn);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {authIn && (



            <Route path="/" element={<Main />}>
              <Route path="admin" element={<Admin />} />
              <Route path="schedules" element={<Schedules />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="gamelist" element={<GameList />} />
              <Route path="divisions" element={<Divisions />} />
              <Route path="settings" element={<Settings />}>
                <Route path="mydetails" element={<MyDeatails />} />
                <Route path="manage/leagues" element={<ManageLeagues />} />
              </Route>
              <Route path="divisions/create" element={<DivisionModal />} />
              <Route
                path="divisions/create/team"
                element={<CreateNewTeamModal />}
              />
              <Route path="season" element={<Season />} />
              <Route path="create/season" element={<CreateSeason />} />
              <Route path="select/division" element={<SelectDivision />} />
              <Route path="seasos/team/name" element={<SeasonTeamName />} />
              <Route path="venues" element={<Venues />} />
              <Route path="People" element={<People />} />
              <Route path="manage" element={<DivisionManage />} />
              <Route path="viewpage" element={<ViewPage />} />
              <Route path="/editpage" element={<EditPage />} />
              <Route path='create/venues' element={<CreateVenues />} />

              <Route path="/aboutschedules" element={<AboutSchedules />} />
              <Route path="*" element={<Navigate to="/admin" replace />} />
            </Route>
          )}
          {!authIn && (
            <Route>
              <Route path="/registration" element={<Registration />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route
                path="/create/newpassword"
                element={<CreateNewPassword />}
              />
              <Route path="/password/updated" element={<PasswordUpdated />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
