import React from 'react'
import style from "../schedulesstyle/schedules.module.css"
import SchedulesDescription from './SchedulesDescription'
import {schedulesData} from "./schedulesData/schedulesData"
const SchedulesName = () => {
  return (
    <div className={style.shedulesName}>
            {
                schedulesData.map((item,index)=>(
                    <SchedulesDescription key={index} item={item}/>
                ))
            }

      
    </div>
  )
}

export default SchedulesName