import React from 'react'
import Container from '../container/Container'
import Table from '../table/Table'
import AdminDivisions from './adminPages/AdminDivisions'
import AdminFilters from './adminPages/AdminFilters'
import AdminHeader from './adminPages/AdminHeader'
import NextPreviusPage from './adminPages/NextPreviusPage'
import "./style/adminStyle.css"
const Admin = () => {
  return (
    <div className='admin'>
        <Container>
          <AdminHeader />
          <AdminDivisions />
          <AdminFilters />
          <Table />
          <NextPreviusPage />
        </Container>
    </div>
  )
}

export default Admin