import React from 'react'
import style from "./style/editModal.module.css"
import done from "../../../../../images/division/done.svg"
const EditModalHeader = () => {
  return (
    <div className={style.editModalHeader}>
        <div className={style.editTravel}>
            <div className={style.done}> <img src={done} alt="done" /> </div>
            <span>Edit Travel Constraints </span>
        </div>

        <div className={style.weekly}>
            <span> Weekly Constraints </span>

            <span> Weekend Constraints </span>
        </div>
    </div>
  )
}

export default EditModalHeader