import React from 'react'
import { Link } from 'react-router-dom'
import style from "../schedulesstyle/schedules.module.css"
const SchedulesDescription = ({item}) => {
  return (
    <div className={style.schedulesDescription}>
      
      
        <div className={style.schedulesImage}>
            <span> {item.schedulesName} </span>
            <Link to="/aboutschedules" className={style.shedulesLink}>
            <img src= {item.icon}  alt='icon'/>
            </Link>
            

             </div>
              <div className={style.divisionName}> {item.divisionName}</div>
              <div className={style.resultes}> {item.results} </div>
              <div className={style.scheduled}> {item.scheduled} </div>
              <div className={style.percent}> 
                
                <img src={item.top} alt="img" width={11.67} height={11.67} />
                <span style={item.style}> {item.percent}</span>
                </div>
                <div className={style.created}> {item.created}</div>
                <div className={style.scheduledData}> {item.data}</div>
            
    </div>
  )
}

export default SchedulesDescription