import React, { useEffect } from 'react'
import FooterManage from './managePage/page/FooterManage'
import HeaderManage from './managePage/page/HeaderManage'
import MiddleManage from './managePage/page/MiddleManage'
import style from "./style/divisionManage.module.css"
import { useDispatch } from 'react-redux'
import { GetAllCoach } from '../../../../component/features/slice/divisionSlice/divisionApi/divisionApi'
const DivisionManage = () => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(GetAllCoach())
  },[])
  return (
    <div className={style.divisionManage}>
      <HeaderManage />
      <MiddleManage />
      <FooterManage />
    </div>
  )
}

export default DivisionManage