import { createAsyncThunk } from "@reduxjs/toolkit";
import {$authHost} from "../../../axios";


export const CreateUser = createAsyncThunk(
    "createUser",
    async({user}) =>{
     
        const response = await  $authHost.post("/register", user)
         return response.data
    }
)

export const LoginUer = createAsyncThunk(
    "login",
    async({loginUser})=>{
        const response = await  $authHost.post("/login",loginUser)
        
       return response.data
    }
)


export const ForgotPassword = createAsyncThunk(
    "forgotpass",
    async({email})=>{
   
             await $authHost.post(`/password/email?email=${email}`)
            
    }
)

export const CreateNewPassword = createAsyncThunk(
    "newpass",
    async({pass})=>{
          
            const response = await $authHost.post("/password/reset",pass)
            return response.data
           
    }
)