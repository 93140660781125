import React from 'react'
import style from "./style/editModal.module.css"
const EditModalFooter = () => {
  return (
    <div className={style.editModalFooter}>

        <div className={style.apply}>
            <input type="checkbox" /> <span> Apply these constraints to all Teams in Division</span>
        </div>

      
</div>
        
        

  )
}

export default EditModalFooter