import { createSlice } from "@reduxjs/toolkit";
import { GetAllCoach, getDivisionTeam } from "./divisionApi/divisionApi";

const divisionSlice = createSlice({
  name: "divisionSlice",
  initialState: {
    divisionTeam: [],
    getAllTeamCoach:[],
    getAllEligibleCoach:[],
    eligible_coach:[],
    team_coach:[]


    
  },
  reducers: {},
  extraReducers: (bulider) => {
    bulider.addCase(getDivisionTeam.fulfilled, (state, action) => {   

      state.divisionTeam.push(...action.payload);
    })
    .addCase(GetAllCoach.fulfilled,(state,action)=>{
      const {team_coach,eligible_coach} = action.payload
      state.team_coach = team_coach;
      state.eligible_coach=eligible_coach
  

    })
  },
});
export const divisionSliceReducer = divisionSlice.reducer;
