import { createSlice } from "@reduxjs/toolkit";
import { CreateUser, LoginUer } from "./loginApi/loginApi";

const loginSlice = createSlice({
  name: "/loginSlice",
  initialState: {
    user: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      organization_name: "",
    },
    authIn: localStorage.getItem("token") || sessionStorage.getItem("token"),
  },
  reducers: {
    createUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    logOut: (state, action) => {
      localStorage.clear();
      sessionStorage.clear();
      state.authIn = false;
    },
  },
  extraReducers: (bulider) => {
    bulider
      .addCase(CreateUser.fulfilled, (state, action) => {
        state.registrationRespons = { ...action.payload };
      })
      .addCase(LoginUer.fulfilled, (state, action) => {
        state.authIn = true;
      });
  },
});

export const { createUser, logOut } = loginSlice.actions;
export const selectAuthIn = (state) => state.hockey.authIn;
export const loginSliceReducer = loginSlice.reducer;
