import React from 'react'
import hockey from "../../images/hockey.svg"

const RegistrationImage = () => {
  return (
    <div>

        <img src={hockey} alt="hockey" />

    </div>
  )
}

export default RegistrationImage