import React from 'react'
import style from "./style/editModal.module.css"
const EditModalMiddle = () => {
  return (
    <div className={style.editModalMiddle}>
       <div className={style.maxDistance}>
         <span> Max distance from home Venue </span>
         <input type="number" />
       </div>

       <div className={style.maxDistance}>
         <span> Max distance from home Venue </span>
         <input type="number" />
       </div>

       <div className={style.maxDistance}>
         <span> Max Time from home Venue </span>
         <input type="number" />
       </div>

       <div className={style.maxDistance}>
         <span> Max Time from home Venue </span>
         <input type="number" />
       </div>

       <div className={style.maxDistance}>
         <span> Max distance from Last Game</span>
         <input type="number" />
       </div>

       <div className={style.maxDistance}>
         <span> Max distance from Last Game</span>
         <input type="number" />
       </div>


       <div className={style.maxDistance}>
         <span> Max Time from Last Game</span>
         <input type="number" />
       </div>

       <div className={style.maxDistance}>
         <span> Max Time from Last Game</span>
         <input type="number" />
       </div>
    </div>
  )
}

export default EditModalMiddle