export const editModalStyle ={
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "387px",
    height: "374px",
    bgcolor: "background.paper",
    display:"flex",
    flexDirection:"column",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px"
}