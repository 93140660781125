import React from 'react'
import style from "../style/gameListStyle.module.css";
const GameListTableData = () => {
  return (
    <>
    <div className={style.gamaeListFooter}>
        <div className={style.gameListChoose}>
          <span> Choose</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Date</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Day</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Start</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Finish</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Visitor</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Home</span>
        </div>
        <div className={style.gameListChoose}>
          <span> Venue</span>
        </div>
      </div>
      <div className={style.gameListData}>
        <div className={style.gameListDataTable}>
          <div className={style.gameLisTableCheckBox}>
            <input type="checkbox" />
          </div>
          <div className={style.gameLisTableContain}>
            <span> Choose</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 10/28/12</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Friday</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 07/06/93 </span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Team name</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 07/06/93 </span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Goodison Park </span>
          </div>
        </div>
      </div>
      <div className={style.gameListData}>
        <div className={style.gameListDataTable}>
          <div className={style.gameLisTableCheckBox}>
            <input type="checkbox" />
          </div>
          <div className={style.gameLisTableContain}>
            <span> Choose</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 10/28/12</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Friday</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 07/06/93 </span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Team name</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 07/06/93 </span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Goodison Park </span>
          </div>
        </div>
      </div>
      <div className={style.gameListData}>
        <div className={style.gameListDataTable}>
          <div className={style.gameLisTableCheckBox}>
            <input type="checkbox" />
          </div>
          <div className={style.gameLisTableContain}>
            <span> Choose</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 10/28/12</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Friday</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 07/06/93 </span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Team name</span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> 07/06/93 </span>
          </div>
          <div className={style.gameLisTableContain}>
            <span> Goodison Park </span>
          </div>
        </div>
      </div>
    </>
    
  )
}

export default GameListTableData