import React from 'react'
import ForgotPasswordForm from './forgotPages/ForgotPasswordForm'
import ForgotPasswordHeader from './forgotPages/ForgotPasswordHeader'
import style from "./style/forgotStyle.module.css"
const ForgotPassword = () => {
  return (
    <div className={style.forgotPassword}>

        <ForgotPasswordHeader />
        <ForgotPasswordForm />

    </div>
  )
}

export default ForgotPassword