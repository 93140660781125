import React from "react";
import style from "./style/gameModalStyle.module.css";
import GameRowTable from "./GameRowTable";
import GameListScrool from "../gameListPages/GameListScrool";
import GamePageNextPrev from "../gameListPages/GamePageNextPrev";
const GameModalFooter = () => {
  return (
    <div className={style.gameModalFooter}>
      <GameRowTable />
      <div className={style.teamPlace}>
            <div className={style.coloumn}>
                 <span> Team 1 </span>
             
            </div>
            <div className={style.coloumn}>
                 <span> 0.5 </span>
             
            </div>
      </div>

      <div className={style.teamPlace}>
            <div className={style.coloumn}>
                 <span> Team 2 </span>
             
            </div>
            <div className={style.coloumn}>
                 <span> 0.5 </span>
             
            </div>
      </div>

      <div className={style.teamPlace}>
            <div className={style.coloumn}>
                 <span> Team 3 </span>
             
            </div>
            <div className={style.coloumn}>
                 <span> 0.5 </span>
             
            </div>
      </div>

      <div className={style.teamPlace}>
            <div className={style.coloumn}>
                 <span> Team 4 </span>
             
            </div>
            <div className={style.coloumn}>
                 <span> 0.5 </span>
             
            </div>
      </div>
      <div className={style.teamPlace}>
            <div className={style.coloumn}>
                 <span> Team 4 </span>
             
            </div>
            <div className={style.coloumn}>
                 <span> 0.5 </span>
             
            </div>
      </div>
    ++
      
     
       

      <div className={style.gameTableScroll}>
      <GameListScrool />
      </div>

      <div className={style.tablePage}>
     <GamePageNextPrev /> 
    </div>
   
      

      

      


    



      
    </div>
  );
};

export default GameModalFooter;
