import React from "react";
import { manageData } from "../../../divisionData/manageData";
import style from "../../style/divisionManage.module.css";
import "../../style/manageData.css";
const FooterManage = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedModal, setSelectedModal] = React.useState(null);
  const handleOpen = ({ index }) => {
    setSelectedModal(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={style.footerManage}>
      {manageData.map(
        (
          {
            buttonName,
            icon,
            name,
            style,      
            Modal,
            goodison,       
            teamName,
            venue,
          },
          index
        ) => (
          <div key={index} className="manageDivison">
            <div className="coach">
              <span>{name}</span>

              <button
                style={style}
                className="addCoach"
                onClick={() => handleOpen({ index })}
              >
                <img src={icon} alt={name} />
                <span>{buttonName}</span>
              </button>
              {selectedModal === index && (
                <Modal handleClose={handleClose} open={open} />
              )}
            </div>
            <div className="coachImage">
              <span> {teamName} </span>
              

              <div className="goodisonText">
                <span>{venue} </span> <p> {goodison}</p>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FooterManage;
